.newsPage{
    .container{
        @media (min-width:1200px) {
            max-width: 1056px;
        }
        @media (max-width:767px) {
            padding: 0 15px;
        }
    }
    .item1{
        padding: 0 60px;
        background: none;
        padding-bottom: 200px;
        @media (max-width:767px) {
            padding: 0 25px;
            padding-bottom: 100px;
        }
    }
    .it1-bx{
        // overflow: hidden;
        @media (max-width:767px) {
            padding-top: 0px;
            padding-bottom: 40px;
        }
        .it-bgbx{
            position: relative;
            overflow: hidden;
        }
    }
    .search-bx{
        margin-top: 75px;
        width: 100%;
        height: 50px;
        display: flex;
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 57px;
        @media (max-width:767px) {
            border-radius: 5px;
            margin-top: 46px;
            margin-bottom: 35px;
        }
        .l{
            width: calc(100% - 150px);
            @media (max-width:767px) {
                width: calc(100% - 70px);
            }
            input{
                background-color: #fef1ea;
                height: 100%;
                padding-left: 33px;
                border-radius: 5px 0 0 5px;
                @include fontset(20,1.2,1,400);
                @media (max-width:767px) {
                    padding-left: 16px;
                    font-size: pxToEm(16);
                }
            }
        }
        .r{
            width: 150px;
            height: 100%;
            @media (max-width:767px) {
                width: 70px;
            }
            a{
                display: block;
                width: 100%;
                height: 100%;
                background-color: #f08300;
                position: relative;
            }
            .send{
                display: block;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 20px;
                }
            }
            .close{
                display: none;
                &::before{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%) rotate(45deg);
                    width: 2px;
                    height: 25px;
                    background: #fff;
                }
                &::after{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%) rotate(-45deg);
                    width: 2px;
                    height: 25px;
                    background: #fff;
                }
            }
        }
        .r.active{
            .send{
                display: none;
            }
            .close{
                display: block;
            }
        }
    }
   
    .b-card{
        padding-bottom: 80px;
        position: relative;
        z-index: 5;
        @media (max-width:991px) {
            padding-bottom: 40px;
        }
        @media (max-width:767px) {
            padding-bottom: 10px;
        }
        a{
            display: flex;
            align-items: center;
            box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            border: 2px solid rgba(255,255,255,255);
            @media (max-width:991px) {
                display: block;
                border: none;
                border-radius: 5px;
            }
            @media (min-width:768px) {
                &:hover{
                    border: 2px solid $hover;
                    .l .bgcover{
                        transform: scale(1.2);
                    }
                    .r .ti::before{
                        width: 100%;
                    }
                    .more-btn{
                        background-color: $hover;
                        color: #fff;
                    }
                }
            }
        }
        .l{
            width: 59.5%;
            overflow: hidden;
            position: relative;
            @media (max-width:991px) {
                width: 100%;
            }
            .bgcover{
                padding-bottom: 69.7%;
                background-size: cover !important;
                transition: .6s ease;
            }
        }
        .r{
            width: 40.5%;
            padding: 0 4.5%;
            @media (max-width:991px) {
                width: 100%;
                padding: 0 10px;
                padding-bottom: 18px;
                background-color: #fff;
            }
            .da{
                @include fontset(20,1.2,1,400,$hover);
                margin-bottom: 8px;
              
                @media (max-width:991px) {
                    margin-top: 10px;
                    margin-bottom: 2px;
                }
                @media (max-width:767px){
                    font-size: pxToEm(16);
                    margin-bottom: 2px;
                }
            }
            .ti{
                @include fontset(22,1.64,2.2,700);
                position: relative;
                @include clamp(1.64,2);
                height: auto;
                max-height: 3.28em;
                padding-bottom: 3px;
                margin-bottom: 10px;
                font-family: $font_content;
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 2px;
                    width: 0;
                    height: 3px;
                    background: $hover;
                    transition: .6s ease;
                }
                @media (max-width:991px) {
                    margin-bottom: 5px;
                }
                @media (max-width:767px){
                    font-size: pxToEm(16);
                }
            }
            .des{
                @include fontset(16,1.88,1.6,400);
                @include clamp(1.88,5);
                padding-bottom: 10px;
                font-family: $font_content;
                @media (max-width:991px) {
                    height: auto;
                }
                @media (max-width:767px){
                    font-size: pxToEm(14);
                }
            }
            .more-btn{
                @include fontset(16,1.2,.8,400,#000);
                padding: 12px 17px;
                border: 1px solid $hover;
                border-radius: 10px;
                background-color: #ffffff;
                font-family: $font_content;
                transition: .4s ease;
                display: block;
                width: 125px;
                margin: 0;
                text-align: center;
                @media (min-width:768px) {
                    &:hover{
                       
                    }
                }
                @media (max-width:767px) {
                    font-size: pxToEm(14);
                    padding: 10px 13px;
                    border-radius: 5px;
                    width: 102px;
                }
            }
        }
    }
    .news-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        margin-top: 35px;
        @media (max-width:767px) {
            display: block;
            margin-top: 25px;
        }
        &::before{
            position: absolute;
            content: '';
            background: url(../images/news/pc_bg-1.png) no-repeat center center / cover;
            width: 1656px;
            height: 365px;
            z-index: 0;
            top: -100px;
            right: -378px;
            @media (max-width:767px) {
                display: none;
            }
        }
        &::after{
            position: absolute;
            content: '';
            background: url(../images/news/pc_bg-2.png) no-repeat center center / cover;
            width: 790px;
            height: 324px;
            z-index: 0;
            bottom: 20%;
            left: -378px;
            @media (max-width:767px) {
                display: none;
            }
        }
        li{
            width: 46%;
            margin-bottom: 110px;
            position: relative;
            z-index: 1;
            @media (max-width:767px) {
                width: 100%;
                margin-bottom: 35px;
                box-shadow: 0 0 14.6px 0.5px rgba(0,0,0,.18);
            }
            a{
                display: block;
                @media (min-width:768px) {
                    &:hover{
                        .img-bx{
                            &::before{
                                opacity: 1;
                            }
                            .more-btn{
                                opacity: 1;
                            }
                        }
                        .ti::before{
                            width: 100%;
                        }
                    }
                }
                @media (max-width:767px) {
                    padding-bottom: 18px;
                }
                .img-bx{
                    position: relative;
                    border-radius: 10px;
                    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
                    overflow: hidden;
                    transition: .4 ease;
                    @media (max-width:767px){
                        border-radius: 5px;
                        box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
                        border-radius: 5px 5px 0 0;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba(#221815 , .7);
                        transition: .4s;
                        opacity: 0;
                    }
                    .more-btn{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        @include fontset(16,1.5,.8,400,#fff);
                        font-family: $font_content;
                        transition: .4s;
                        opacity: 0;
                        img{
                            position: relative;
                            top: -3px;
                            margin-right: 10px;
                        }
                    }
                }
                .da{
                    @include fontset(20,1.2,1,400,$hover);
                    margin-bottom: 8px;
                    margin-top: 19px;
                    @media (max-width:767px){
                        font-size: pxToEm(16);
                        margin-bottom: 2px;
                        margin-top: 14px;
                        padding: 0 10px;
                    }
                }
                .ti{
                    @include fontset(22,1.64,2.2,700);
                    position: relative;
                    @include clamp(1.64,2);
                    height: auto;
                    max-height: 3.28em;
                    padding-bottom: 3px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    font-family: $font_content;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 2px;
                        width: 0;
                        height: 3px;
                        background: $hover;
                        transition: .6s ease;
                    }
                    @media (max-width:767px){
                        margin-top: 2px;
                        margin-bottom: 5px;
                        font-size: pxToEm(16);
                        padding: 0 10px;
                    }
                }
                .des{
                    @include fontset(16,1.88,1.6,400);
                    @include clamp(1.88,3);
                    padding-bottom: 10px;
                    font-family: $font_content;
                    max-height: 5.64em;
                    height: auto;
                    @media (max-width:767px){
                        font-size: pxToEm(14);
                        padding: 0 10px;
                    }
                }
                .more-btn2{
                    @include fontset(16,1.2,.8,400,#000);
                    padding: 12px 17px;
                    border: 1px solid $hover;
                    border-radius: 10px;
                    background-color: #ffffff;
                    font-family: $font_content;
                    transition: .4s ease;
                    width: 125px;
                    margin: 0;
                    text-align: center;
                    @media (max-width:767px) {
                        font-size: pxToEm(14);
                        padding: 10px 13px;
                        border-radius: 5px;
                        width: 102px;
                        margin-top: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .page{
        padding-bottom: 80px;
        @media (max-width:767px){
            padding-bottom: 0px;
        }
    }
}


.newsdetailPage{
    .container{
        @media (min-width:1200px) {
            max-width: 1056px;
        }
        @media (max-width:767px) {
            padding: 0 15px;
        }
    }
    .item1{
        padding: 0 60px;
        padding-bottom: 200px;
        background: none;
        @media (max-width:767px){
            padding: 0 25px;
            padding-bottom: 100px;
        }
        .it1-bx{
            padding-bottom: 50px;
            @media (max-width:767px){
                padding-bottom: 25px;
            }
        }
        .ti-bx{
            margin-bottom: 30px;
            @media (max-width:767px){
                margin-bottom: 15px;
            }
        }
        .ti-bx .da{
            @include fontset(20,1.2,1,400,$hover);
            margin-bottom: 2px;
            
            @media (max-width:767px){
                font-size: pxToEm(16);
                margin-bottom: 1px;
            }
        }
        .ti-bx .ti{
            @include fontset(22,1.64,2.2,700);
            position: relative;
            @include clamp(1.64,2);
            height: auto;
            max-height: 3.28em;
            padding-bottom: 1px;
            font-family: $font_content;
            &::before{
                position: absolute;
                content: '';
                bottom: -1px;
                left: 0;
                width: 25px;
                height: 3px;
                background: $hover;
                z-index: 3;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                z-index: 2;
                background: #dcdddd;
            }
            @media (max-width:767px){
                font-size: pxToEm(16);
            }
        }
        .editor_Content{
            margin-bottom: 80px;
            @media (max-width:767px){
                margin-bottom: 40px;
            }
        }
        .back-btn{
            @include fontset(16,1.2,.8,400,#000);
            padding: 12px 17px;
            border: 1px solid $hover;
            border-radius: 10px;
            background-color: #ffffff;
            font-family: $font_content;
            transition: .4s ease;
            display: block;
            width: 125px;
            margin: 0 auto;
            margin-bottom: 140px;
            text-align: center;
            @media (min-width:768px) {
                &:hover{
                    background-color: $hover;
                    color: #fff;
                }
            }
            @media (max-width:767px) {
                font-size: pxToEm(14);
                padding: 10px 13px;
                border-radius: 5px;
                margin-bottom: 100px;
                width: 102px;
            }
            // width: 90px;
            // margin: 0 auto;
            // display: block;
            // padding-left: 45px;
            // position: relative;
            // .hov{
            //     opacity: 1;
            // }
            // .unhov{
            //     opacity: 0;
            // }
            // img{
            //     transition: .4s ease;
            //     position: absolute;
            //     left: 0;
            //     width: 35px;
            //     top: 43%;
            //     transform: translateY(-50%);
            // }
            // span{
            //     @include fontset(16,1.5,.8,400);
            //     transition: .4s ease;
            //     font-family: $font_content;
            //     @media (max-width:767px){
            //         font-size: pxToEm(14);
            //     }
            // }
            // @media (min-width:768px){
            //     &:hover{
            //         .hov{
            //             opacity: 0;
            //         }
            //         .unhov{
            //             opacity: 1;
            //         }
            //         span{
            //             color: $hover;
            //         }
            //     }
            // }
        }
    }
}