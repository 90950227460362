.indexPage{
    .ind-bx{
        text-align: center;
        margin-top: 135px;
        @media (max-width:767px){
            margin-top: 65px;
        }
        .in-ti{
            @include fontset(64,1.3,.25,700,#fff);
            text-shadow: black 0.1em 0.1em 0.2em;
            @media (max-width:767px){
                font-size: pxToEm(31);
                margin-bottom: 15px;
            }
            
        }
        .in-des{
            @include fontset(35,1.5,.25,500,#fff);
            text-shadow: black 0.1em 0.1em 0.2em;
            @media (max-width:767px){
                font-size: pxToEm(21);
            }
        }
    }
    .index-banner{
        position: fixed;
        top: 0;
        z-index: 0;
        height: 100vh;
        width: 100%;
        z-index: -1;
        .slick-current.slick-active{
            .bgcover{
                animation: banani 5.2s linear forwards;
                @media (max-width:767px) {
                    animation: bananimo 5.2s linear forwards;
                }
            }
        }
        @keyframes banani {
            0%{
                transform: translateX(-50px);
            }
            100%{
                transform: translateX(0px);
            }
        }
        @keyframes bananimo {
            0%{
                transform: translateX(-25px);
            }
            100%{
                transform: translateX(0px);
            }
        }
        .bgcover{
            width:calc(100% + 50px);
            height: 100vh;
            background-size: cover !important;
            background-position-y: 100px !important;
            transform: translateX(0px);
            @media (max-width:767px) {
                background-position-y: 70px !important;
                width:calc(100% + 25px);
            }
        }
        .slick-dots{
            width: 190px;
            height: 50px;
            position: absolute;
            margin: 0;
            left: 10vw;
            bottom: 5vh;
            // border: 1px solid #586c6b;
            pointer-events: none;
           
            li{
                display: flex;
                width: 100%;
            }
            @media (max-width:767px) {
                // width: 60px;
                // height: 60px;
                // position: absolute;
                // top: -100px;
                // left: auto;
                // right: 10px;
                // transform: none;
                width: 120px;
                bottom: 16vh;
                left: 6vw;
            }
            li.slick-active{
                opacity: 1;
                i{
                    &::after{
                        animation: dotani 5.3s linear forwards;
                        @media (max-width:767px) {
                            animation: dotanimo 5.3s linear forwards;
                        }
                    }
                }
            }
            @keyframes dotani {
                0%{
                    width: 0;
                }
                100%{
                    width: 100px;
                }
            }
            @keyframes dotanimo {
                0%{
                    width: 0;
                }
                100%{
                    width: 50px;
                }
            }
            li{
                @include fontset(16,1,.6,300,#ffffff);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin: 0 ;
                height: auto !important;
                opacity: 0;
                transition: .4s ease;
                font-family: $font_content;
                i{
                    color: $hover;
                    padding-right: 120px;
                    margin-right: 20px;
                    position: relative;
                    @media (max-width:767px) {
                        padding-right: 50px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 36px;
                        top: 60%;
                        transform: translateY(-50%);
                        width: 100px;
                        height: 2px;
                        background-color: #fff;
                        @media (max-width:767px) {
                            left: 18px;
                            width: 50px;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        left: 36px;
                        top: 60%;
                        transform: translateY(-50%);
                        height: 2px;
                        background-color: $hover;
                        @media (max-width:767px) {
                            left: 18px;

                        }
                    }
                }
                @media (max-width:767px) {
                    font-size: pxToEm(13);
                }
            }
        }
    }
    .item1{
        padding: 0 54px;
        margin-top: 230px;
        padding-bottom: 10px;
        position: relative;
        @media (max-width:767px) {
            padding: 0 25px;
            padding-bottom: 10px;
            margin-top: 115px;
        }
        &::before{
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 10px;
            background-color: #f0f0f0;
        }
        .it1-bx{
            background:#fff;
            box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
            position: relative;
            padding-bottom: 80px;
            padding-top: 15px;
            @media (max-width:767px) {
                padding-top: 15px;
                padding-bottom: 35px;
            }
            &::before{
                position: absolute;
                content: '';
                top: -94px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 95px;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                background-color: #fff;
                @media (max-width:767px) {
                    height: 43px;
                    top: -42px;
                }
            }
            &::after{
                position: absolute;
                content: '';
                right: -50px;
                bottom: 0;
                background: url(../images/sketch01.png) no-repeat center center / contain;
                width: 496px;
                height: 520px;
                pointer-events: none;
                z-index: 0;
                @media (max-width:767px) {
                    width: 246px;
                    height: 270px;
                }
            }
            .container{
                position: relative;
                z-index: 1;
                @media (max-width:767px) {
                    padding: 0 15px;
                }
            }
        }
        .it1-list{
            display: flex;
            justify-content: space-between;
            margin-bottom: 75px;
            @media (max-width:767px) {
                display: block;
                margin-bottom: 40px;
            }
            li{
                width: 32.5%;
                @media (max-width:767px) {
                    width: 100%;
                    &:not(:last-child){
                        margin-bottom: 40px;
                    }
                }
                .ti-bx{
                    margin-bottom: 35px;
                    text-align: center;
                    @media (max-width:767px) {
                        margin-bottom: 18px;
                    }
                    .en-ti{
                        @include fontset(32,1,3.2,400,$hover);
                        margin-bottom: 10px;
                        @media (max-width:767px) {
                            font-size: pxToEm(26);
                        }
                    }
                    .ti{
                        @include fontset(30,1,3,400);
                        @media (max-width:767px) {
                            font-size: pxToEm(21);
                        }
                    }
                }
                .it-obx{
                    display: block;
                    position: relative;
                    @media (min-width:768px) {
                        &:hover{
                            .img-bx::before{
                                opacity: 1;
                            }
                            .des-bx{
                                opacity: 1;
                            }
                        }     
                    }
                    .img-bx{
                        border-radius: 10px;
                        overflow: hidden;
                        position: relative;
                        @media (max-width:767px) {
                            border-radius: 5px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,.7);   
                            transition: .4s ease;
                            opacity: 0;
                            @media (max-width:767px) {
                                opacity: 1;
                            }
                        }
                    }
                    .des-bx{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        max-height: 80%;
                        overflow-y: auto;
                        transition: .4s ease;
                        opacity: 0;
                        @media (max-width:767px) {
                            opacity: 1;
                        }
                        .morimg{
                            text-align: center;
                            margin-bottom: 12px;
                            display: block;
                        }
                        .des{
                            @include fontset(20,2,.5,400,#ffffff);
                            text-align: center;
                            font-family: $font_content;
                            display: block;
                            @media (max-width:767px) {
                                font-size: pxToEm(16);
                                line-height: 1.8;
                            }
                        }
                    }
                }
            }
        }
        .sh-tagbx{
            margin-top: 60px;
            margin-bottom: 35px;
            @media (max-width:767px) {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
        .sear-btn{
            width: 193px;
            height: 56px;
            border-radius: 10px;
            background-color: #f08300;
            border: 1px solid $hover;
            transition: .4s ease;
            display: block;
            margin: 0 auto;
            text-align: center;
            @media (max-width:767px) {
                width: 100%;
                border-radius: 5px;
            }
            span{
                position: relative;
                @include fontset(16,3.8,1,400,#ffffff);
                padding-left: 32px;
                transition: .4s ease;
                img{
                    position: absolute;
                    top: 42%;
                    transform: translateY(-50%);
                    left: 0;
                    transition: .4s ease;
                    width: 17px;
                }
                .unhove{
                    opacity: 1;
                }
                .hove{
                    opacity: 0;
                }
            }
            @media (min-width:768px) {
                &:hover{
                    background-color: #fff;
                    span{
                        color: $hover;
                    }
                    .unhove{
                        opacity: 0;
                    }
                    .hove{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .item2{
        padding-top: 70px;
        background-color: #f0f0f0;
        position: relative;
        @media (max-width:767px) {
            padding-top: 30px;
        }
        .sh-tibx{
            margin-bottom: 80px;
            @media (max-width:767px) {
                margin-bottom: 35px;
            }
        }
        .it2-list{
            li{
                width: 100%;
                position: relative;
                a{
                    display: flex;
                    width: 100%;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 0;
                        width: 56%;
                        height: 100%;
                        background-color: #000;
                        opacity: 0;
                        z-index: 8;
                        transition: all .6s cubic-bezier(.25,.1,.25,1);
                    }
                    @media (min-width:768px) {
                        &:hover{
                            &::before{
                                width: 100%;
                                opacity: .7;
                            }
                            .s-img{
                                .bgcover{
                                    opacity: 0;
                                }
                                .tx-bx{
                                    opacity: 1;
                                    transition: all .6s cubic-bezier(.25,.1,.25,1) .2s;
                                }
                            }
                            .b-img{
                                opacity: 0;
                            }
                            .all-img{
                                opacity: 1;
                            }
                        }
                       
                    }
                }
                .s-img{
                    width: 44%;
                    position: relative;
                    z-index: 10;
                    @media (max-width:767px) {
                        width: 100%;
                    }
                    .bgcover{
                        transition: all .6s cubic-bezier(.25,.1,.25,1);
                    }
                    .tx-bx{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 12.2%;
                        max-width: 296px;
                        width: 100%;
                        opacity: 0;
                        transition: all .6s cubic-bezier(.25,.1,.25,1) ;
                        .ti{
                            @include fontset(28,1,1,700,#fff);
                            font-family: $font_content;
                            margin-bottom: 13px;
                        }
                        .des{
                            @include fontset(16,1.8,1,400,#fff);
                            height: 84px;
                            position: relative;
                            overflow: hidden;
                            font-family: $font_content;
                        }
                    }
                }
                .b-img{
                    width: 56%;
                    position: absolute;
                    right: 0;
                    z-index: 5;
                    transition: all .6s cubic-bezier(.25,.1,.25,1);
                    @media (max-width:767px) {
                        width: 0;
                    }
                    .bgcover{
                        background-size: cover !important;
                        padding-bottom: 41.1%;
                    }
                }
                .all-img{
                    position: absolute;
                    right: 0;
                    width: 100%;
                    top: 0;
                    transition: all .6s cubic-bezier(.25,.1,.25,1);
                    z-index: 6;
                    opacity: 0;
                    .bgcover{
                        padding-bottom: 22.5%;
                    }
                }
                .bgcover{
                    background-size: cover !important;
                    padding-bottom: 51.1%;
                    @media (max-width:767px) {
                        padding-bottom: 51.8%;
                    }
                }
                &:nth-child(even){
                    .s-img{
                        margin-left: auto;
                    }
                    .b-img{
                        left: 0;
                    }
                }
            }
        }
    }
}