/*----------------------通用設定------------------------*/

//選取顏色
p,
h1,
h2,
h3,
h4,
b,
strong,
span,
li,
div,
a,
img,
tr,
td,
th {
    &::selection {
        color: $white;
        background: $hover;
    }
}
html{
    scroll-behavior: smooth;
}


body.modal-open{   
    overflow: hidden;
    .overbg,.overlay{
        opacity: 1;     
    }
    .overbg{
        pointer-events: auto;
    }
}

body {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: 100%;
    line-height: 1em;
    overflow: auto;
    font-size: 16px;
    color: #000000;
    word-break: break-word;
    letter-spacing: 0.1em;
    z-index: 0;
    background: #ffffff;
    -webkit-text-size-adjust: 100%;
    opacity: 0;
    transition: 1.2s;
    font-family: $bil $font_content;
    .overbg,.overlay{
        opacity: 0;         
    }
}
body.loading{
    opacity: 1;
    transition: opacity 1.2s;
}

//a tag
a {
    text-decoration: none;
    outline: 0;
}

a,
a:hover {
    transition: 0.4s;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

//phone
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

a[href^="tel"] {
    color: inherit;
    /* Inherit text color of parent element. */
    text-decoration: none;
    /* Remove underline. */
    /* Additional css `propery: value;` pairs here… */
}

//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

//jqimgFill 
.jqimgFill {
    position: relative;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: inline-block;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

.jqimgFill img,
.jqimgFill-tc img {
    left: 0;
    top: 0;
    margin: 0;
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    max-width: none;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

// input,select,textarea
input,
select,
textarea {
    width: 100%;
    height: 56px;
    outline: none;
    padding: 0;
    color: $black;

    border: 1px solid $white;
    font-size: pxToEm(16);
    border-radius: 5px;

    @include screen(640px) {
        font-size: pxToEm(15);
    }

    &:focus {
        transition: .4s;
        border: 1px solid #eee;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background: #000 !important;
}

textarea {
    height: 218px;
    padding: 10px;
}

// input placeholder
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #666;
    line-height: 18px;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    line-height: 43px;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    line-height: 43px;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #666;
    line-height: 18px;
}

//移除 input type="date"箭頭
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type='date'],
input[type='time'] {
    -webkit-appearance: none;
}

select {
    font-family: $font_content;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    //background: url(../svg/arrw_1.svg?20181225) no-repeat scroll 96.9% center transparent;
    background-size: 3.4%;
    padding-right: 14px;
    height: 40px;
    width: 100%;
}

select::-ms-expand {
    display: none;
}

.selectBox {
    background: #FFF;
}

// header fix狀態時 錨點用
body :target:before {
    display: block;
    content: "";
    height: 120px;
    margin: -120px 0 0;

    @media screen and (max-width: 960px) {
        height: 100px;
        margin: -100px 0 0;
    }
}

body .slide-down :target:before {
    display: block;
    content: "";
    height: 120px;
    margin: -120px 0 0;
}

.slick-slide {
    outline: 0;
}






/*----------------------大區塊------------------------*/

#Wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    // min-height: 100%;
    // min-width: 320px !important;
    overflow: hidden;
    z-index: 21;
}

main[role="main"] {
    display: block;
    width: 100%;
    @extend %cf;
    position: relative;
    z-index: 1;
    margin-top: 100px;
    @media (max-width:767px) {
        margin-top: 70px;
    }
}

section {
    position: relative;
    @extend %cf;
}

article {
    @include secction_width(1200px, 85%);
    position: relative;
    z-index: 1;
}


/*----------------------每個選單Highlight------------------------*/




/*----------------------內頁單元標題------------------------*/

.title_box {}

/*----------------------tag_list------------------------*/
.tag_list {
    a {
        color: $white;
        text-decoration: none;
        @include border(10px);
        background: #434343;
        padding: 3px 7px;
        font-size: pxToEm(15);

        &:not(:last-child) {
            margin-right: 15px;
        }

        &:hover {
            background: #2f3a88;
        }
    }
}

/*----------------------編輯器------------------------*/

.editor_Content {
    font-family: $font_content;
    font-size: pxToEm(16);
    line-height: 1.88;
    ol{
        list-style-type: decimal;
        padding-left: 10px;
    }
    ul {
        list-style-type: initial;
        padding-left: 20px;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    b,strong{
      font-weight: bold;
      font-size: inherit;
    }
    em{
     font-style: italic; 
    }
    a{
      text-decoration: underline;
      color:#0782C1;
    }
    iframe {
        max-width: 100%;
    }
    h1,h2,h3,h4,h5,h6 {
        margin: inherit;
    }
    blockquote{
      font-style: italic;
      padding: 2px 20px 0 8px;
      margin-left: 40px;
      border-left:5px solid #ccc;
    }
    td, th, tr{
        margin: initial;
    }
    table{
        border-collapse: inherit;
    }
}

.editor_Box {
    margin: auto;
    line-height: 1.5;
    font-size: pxToEm(14);
    color: #000000;

    ul,
    ol {
        padding-left: 40px;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    iframe {
        max-width: 100%;
        @media screen and (max-width: 767px) {
            height: 100% !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: inherit;
    }

}

.mo_use {
    display: none;
}


/*----------------------back------------------------*/

.back_btn,
.more_btn {
    width: 100%;
    margin-top: 70px;
    font-size: pxToEm(18);

    a {
        margin: auto;
        display: inline-block;
        color: $white;
        text-decoration: none;
        padding: 19.5px 49px;
        background-color: $blue;
        @include border(5px);

        &:hover {
            background: $blue;
            opacity: .5;
        }
    }
}

/*----------------------上下則------------------------*/

.seesaw {
    position: relative;
    width: 100%;
    font-size: pxToEm(16);
    margin-top: 61px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: $orange;
        text-decoration: none;
    }

    .left_button:before,
    .right_button:before,
    .back_button {
        transition: 0.4s;
    }

    .left_button,
    .right_button {
        position: absolute;

        &:before {
            content: '';
            display: block;
            width: 70px;
            height: 2px;
            background: $orange;
            margin-bottom: 2px;
        }

        &:hover:before {
            width: 90px;
        }
    }

    .left_button {
        right: 0;
    }

    .right_button {
        left: 0;
        text-align: right;
    }

    .back_button {
        font-size: pxToEm(16);
        width: 230px;
        height: auto;
        padding: 11px 0;
        border: 1px solid $orange;
        text-align: center;

        &:hover {
            background: $orange;

            a {
                color: $white;
            }
        }
    }
}


/*----------------------GO TOP------------------------*/
.top_btn.show-topbtn{
    opacity: 1;
    @media (max-width:767px) {
        opacity: .5;
    }
}
.top_btn {
    cursor: pointer;
    transition: .4s;
    opacity: 0;
    position: fixed;
    bottom: 60px;
    right: 210px;
    z-index: 8;
    font-size: pxToEm(12);
    z-index: 9;
    width: 65px;
    height: 65px;
    border-radius: 10px;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    @media (max-width:1499px) {
        right: 3.6%;
    }
    @media (max-width:991px) {
        right: 20px;
    }
    @media (max-width:767px) {
        box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
    }

    @media (min-width:768px) {
        &:hover{
            background-color: $hover;
            span{
                color: #fff;
                &::before{
                    border-bottom: 13px solid #fff;
                    border-left: 9px dashed transparent;
                    border-right: 9px dashed transparent;
                }
            }
        }
    }
    span{
        margin-top: 16px;
        padding-top: 16px;
        @include fontset(16,1.5,1.6,400);
        font-family: $font_content;
        text-align: center;
        position: relative;
        display: block;
        transition: .4s ease;
        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 13px solid #f08300;
            border-left: 9px dashed transparent;
            border-right: 9px dashed transparent;
            transition: .4s ease;
        }
    }

    &.fix {
        position: absolute;
        transition: 0s;

        @include screen(768px) {
            bottom: 30px;
        }
    }
}


/*----------------------頁數------------------------*/
$lr_btn:30px;

%list_a {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.page {
    font-size: pxToEm(16);
    clear: both;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 15px;
    @media (max-width:767px) {
        font-size: pxToEm(14);
    }
    dt,
    dd {
        vertical-align: middle;
        display: inline-block;

        a {
            display: block;
            height: 32px;
            line-height: 30px;
            text-decoration: none;
            color: #000;
            font-family: $font_content;
            @media (min-width:768px) {
                &:hover {
                    color: #fff;
                    &::before{
                        opacity: 1;
                    }
                }
            }
            
        }
    }

    dd {
        width: 5.7%;
        @media (max-width:767px) {
            width: 15%;
        }
        @media (max-width:350px) {
            width: 13%;
        }
        a{
            position: relative;
            &::before{
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 60%;
                height: 33px;
                background-color: $hover;
                opacity: 0;
                transition: .4s ease;
                border-radius: 10px;
                @media (max-width:767px) {
                    width: 54%;
                    height: 27px;
                    border-radius: 5px;
                }
            }
            span{
                position: relative;
            }
        }
    }

    dd.active a {
        @include border();
        background: none;
        padding: 0;
        color: #fff;
        font-weight: bold;
        &::before{
            opacity: 1;
        }
    }

    dt.rtbn a,
    dt.ltbn a {
        width: auto;
        height: 32px;
        transition: 0.4s;
        @include border(99em);
        background: rgba(255,255,255,0);

    }

    dt.ltbn.nopage a{
        // opacity: 0.2;
        pointer-events: none;
        &:hover{
          
        }
    }
    
    dt.rtbn.nopage a{
        pointer-events: none;
        &:hover{
           
        }
    }

    dt.ltbn a {
        margin-right: $lr_btn;
        position: relative;
        padding-left: 22px;
        @media (max-width:767px) {
            margin-right: 0;
            padding-left: 17px;
            span{
                display: none;
            }
        }
        &:before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border-right: 10px solid $hover;
            border-top: 7px dashed transparent;
            border-bottom: 7px dashed transparent;
            transition: .4s;
        }

        @media (min-width:768px) {
            &:hover {
                color: $hover;
                &::before{
                    border-right: 10px solid $hover;
                    border-top: 7px dashed transparent;
                    border-bottom: 7px dashed transparent;
                }
            }
        }
    }

    dt.rtbn a {
        margin-left: $lr_btn;
        padding-right: 22px;
        position: relative;
        @media (max-width:767px) {
            margin-left: 0;
            padding-right: 17px;
            span{
                display: none;
            }
        }
        &:before {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border-left: 10px solid $hover;
            border-top: 7px dashed transparent;
            border-bottom: 7px dashed transparent;
            transition: .4s; ;
        }

        @media (min-width:768px) {
            &:hover {
                color: $hover;
                &::before{
                    border-left: 10px solid $hover;
                    border-top: 7px dashed transparent;
                    border-bottom: 7px dashed transparent;
                }
            }
        }
    }
}


//版本使用
@include screen(768px) {
    .mo_use {
        display: block;
    }

    .pc_use {
        display: none;
    }

    .top_btn {
        right: 20px;
    }

    right: 20px;
}


@media (max-width:767px) {

    .top_btn a:hover {}

   
    .back_btn,
    .more_btn {
        font-size: pxToEm(17);

        a {
            padding: 12.5px 25px;
            border-radius: 4px;
        }
    }

}