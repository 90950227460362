@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Bilbo&family=Noto+Sans+TC:wght@300;400;500;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #f08300; }

html {
  scroll-behavior: smooth; }

body.modal-open {
  overflow: hidden; }
  body.modal-open .overbg, body.modal-open .overlay {
    opacity: 1; }
  body.modal-open .overbg {
    pointer-events: auto; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Bilbo" "Noto Sans TC"; }
  body .overbg, body .overlay {
    opacity: 0; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC";
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 21; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 100px; }
  @media (max-width: 767px) {
    main[role="main"] {
      margin-top: 70px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  font-family: "Noto Sans TC";
  font-size: 1rem;
  line-height: 1.88; }
  .editor_Content ol {
    list-style-type: decimal;
    padding-left: 10px; }
  .editor_Content ul {
    list-style-type: initial;
    padding-left: 20px; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold;
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #000000; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: 100% !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn.show-topbtn {
  opacity: 1; }
  @media (max-width: 767px) {
    .top_btn.show-topbtn {
      opacity: .5; } }

.top_btn {
  cursor: pointer;
  transition: .4s;
  opacity: 0;
  position: fixed;
  bottom: 60px;
  right: 210px;
  z-index: 8;
  font-size: 0.75rem;
  z-index: 9;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
  background-color: #ffffff; }
  @media (max-width: 1499px) {
    .top_btn {
      right: 3.6%; } }
  @media (max-width: 991px) {
    .top_btn {
      right: 20px; } }
  @media (max-width: 767px) {
    .top_btn {
      box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18); } }
  @media (min-width: 768px) {
    .top_btn:hover {
      background-color: #f08300; }
      .top_btn:hover span {
        color: #fff; }
        .top_btn:hover span::before {
          border-bottom: 13px solid #fff;
          border-left: 9px dashed transparent;
          border-right: 9px dashed transparent; } }
  .top_btn span {
    margin-top: 16px;
    padding-top: 16px;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 1.6px;
    font-weight: 400;
    color: #000000;
    font-family: "Noto Sans TC";
    text-align: center;
    position: relative;
    display: block;
    transition: .4s ease; }
    .top_btn span::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 13px solid #f08300;
      border-left: 9px dashed transparent;
      border-right: 9px dashed transparent;
      transition: .4s ease; }
  .top_btn.fix {
    position: absolute;
    transition: 0s; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: 30px; } }

/*----------------------頁數------------------------*/
.page {
  font-size: 1rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 15px; }
  @media (max-width: 767px) {
    .page {
      font-size: 0.875rem; } }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #000;
      font-family: "Noto Sans TC"; }
      @media (min-width: 768px) {
        .page dt a:hover,
        .page dd a:hover {
          color: #fff; }
          .page dt a:hover::before,
          .page dd a:hover::before {
            opacity: 1; } }
  .page dd {
    width: 5.7%; }
    @media (max-width: 767px) {
      .page dd {
        width: 15%; } }
    @media (max-width: 350px) {
      .page dd {
        width: 13%; } }
    .page dd a {
      position: relative; }
      .page dd a::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 33px;
        background-color: #f08300;
        opacity: 0;
        transition: .4s ease;
        border-radius: 10px; }
        @media (max-width: 767px) {
          .page dd a::before {
            width: 54%;
            height: 27px;
            border-radius: 5px; } }
      .page dd a span {
        position: relative; }
  .page dd.active a {
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #fff;
    font-weight: bold; }
    .page dd.active a::before {
      opacity: 1; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: auto;
    height: 32px;
    transition: 0.4s;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: rgba(255, 255, 255, 0); }
  .page dt.ltbn.nopage a {
    pointer-events: none; }
  .page dt.rtbn.nopage a {
    pointer-events: none; }
  .page dt.ltbn a {
    margin-right: 30px;
    position: relative;
    padding-left: 22px; }
    @media (max-width: 767px) {
      .page dt.ltbn a {
        margin-right: 0;
        padding-left: 17px; }
        .page dt.ltbn a span {
          display: none; } }
    .page dt.ltbn a:before {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border-right: 10px solid #f08300;
      border-top: 7px dashed transparent;
      border-bottom: 7px dashed transparent;
      transition: .4s; }
    @media (min-width: 768px) {
      .page dt.ltbn a:hover {
        color: #f08300; }
        .page dt.ltbn a:hover::before {
          border-right: 10px solid #f08300;
          border-top: 7px dashed transparent;
          border-bottom: 7px dashed transparent; } }
  .page dt.rtbn a {
    margin-left: 30px;
    padding-right: 22px;
    position: relative; }
    @media (max-width: 767px) {
      .page dt.rtbn a {
        margin-left: 0;
        padding-right: 17px; }
        .page dt.rtbn a span {
          display: none; } }
    .page dt.rtbn a:before {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      border-left: 10px solid #f08300;
      border-top: 7px dashed transparent;
      border-bottom: 7px dashed transparent;
      transition: .4s; }
    @media (min-width: 768px) {
      .page dt.rtbn a:hover {
        color: #f08300; }
        .page dt.rtbn a:hover::before {
          border-left: 10px solid #f08300;
          border-top: 7px dashed transparent;
          border-bottom: 7px dashed transparent; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 767px) {
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(19, 19, 20, 0);
  z-index: 32; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 0 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  background-color: #fff; }
  @media (max-width: 1366px) {
    .header_box {
      padding: 0 50px; } }
  @media (max-width: 991px) {
    .header_box {
      padding: 0 20px; } }
  .header_box::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 9px;
    background: rgba(255, 255, 255, 0);
    bottom: 0;
    left: 0;
    z-index: 53;
    box-shadow: 0px 10px 10.8px 1.3px rgba(0, 0, 0, 0.1); }
    @media (max-width: 991px) {
      .header_box::before {
        box-shadow: 0 10px 8.8px 0.3px rgba(0, 0, 0, 0.1); } }

.logo {
  width: 197px;
  height: 42px;
  background: url(../images/logo.png) top left no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative; }
  .nav_box ul {
    display: flex; }
    .nav_box ul li {
      position: relative;
      text-align: center; }
    .nav_box ul li:not(:last-child) {
      margin-right: 54px; }
      @media (max-width: 991px) {
        .nav_box ul li:not(:last-child) {
          margin-right: 20px; } }
      .nav_box ul li:not(:last-child)::before {
        position: absolute;
        content: '';
        right: -26px;
        top: 3px;
        width: 1px;
        height: 26px;
        background-color: #dcdddd; }
        @media (max-width: 991px) {
          .nav_box ul li:not(:last-child)::before {
            right: -10px; } }
    .nav_box ul li.active a {
      color: #f08300; }
      .nav_box ul li.active a::after {
        opacity: 1; }
  .nav_box a {
    width: 100%;
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 1.6px;
    font-weight: 400;
    color: #000000;
    font-family: "Noto Sans TC";
    position: relative;
    font-size: 1.25rem; }
    .nav_box a::after {
      position: absolute;
      content: '';
      bottom: -38px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #f08300;
      transition: .4s ease;
      opacity: 0; }
    @media (min-width: 768px) {
      .nav_box a:hover {
        color: #f08300; }
        .nav_box a:hover::after {
          opacity: 1; } }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 23px;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 16px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 31px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  transform: translateY(7px);
  background: white;
  transition: all 0ms 300ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  background: #f08300;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  background: #f08300;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 767px) {
  .logo {
    width: 192px;
    height: 34px; }
  .header_box {
    width: 100%;
    height: 70px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 23px; }
  .nav_box nav {
    width: 100%;
    margin: auto;
    position: relative;
    top: 44%;
    transform: translateY(-50%); }
  .nav_box {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #fff; }
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          padding: 40px 15px;
          text-align: center;
          text-decoration: none; }
          .nav_box ul li a::before, .nav_box ul li a::after {
            display: none; }
          .nav_box ul li a span {
            position: relative; }
            .nav_box ul li a span::before {
              position: absolute;
              content: '';
              bottom: -9px;
              width: 100%;
              height: 1px;
              background-color: #f08300;
              opacity: 0; }
      .nav_box ul li.active a {
        color: #f08300; }
        .nav_box ul li.active a span:before {
          opacity: 1; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

@media (max-width: 767px) and (max-height: 650px) {
  .nav_box ul li a {
    padding: 22px 15px; } }

footer {
  z-index: 28;
  position: relative;
  padding: 36px 210px 30px 210px;
  background-color: #f0f0f0;
  box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18); }
  footer::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background: url(../images/pc_footer_L.jpg) no-repeat center center/contain;
    width: 195px;
    height: 100%;
    z-index: -1; }
    @media (max-width: 767px) {
      footer::before {
        display: none; } }
  footer::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 177px;
    height: 100%;
    z-index: -1;
    background: url(../images/pc_footer_R.jpg) no-repeat center center/contain; }
    @media (max-width: 767px) {
      footer::after {
        display: none; } }
  @media (max-width: 1366px) {
    footer {
      padding: 36px 100px 30px 100px; } }
  @media (max-width: 991px) {
    footer {
      padding: 36px 20px 30px 20px; } }
  @media (max-width: 767px) {
    footer {
      padding: 33px 20px 30px 20px;
      background: url(../images/mb_footer.jpg) no-repeat center center/cover;
      margin-bottom: 45px; } }
  footer .f-obx {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767px) {
      footer .f-obx {
        display: block;
        text-align: center; } }
    footer .f-obx .l .f-logo {
      width: 147px;
      height: 20px;
      background: url(../images/f-logo.png) no-repeat center center/cover; }
      @media (max-width: 991px) {
        footer .f-obx .l .f-logo {
          width: 119px;
          height: 15px; } }
      @media (max-width: 767px) {
        footer .f-obx .l .f-logo {
          margin: 0 auto;
          margin-bottom: 22px; } }
    footer .f-obx .r ul {
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        footer .f-obx .r ul {
          display: block; } }
      footer .f-obx .r ul li div {
        font-size: 1rem;
        line-height: 1;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #000000;
        font-family: "Noto Sans TC"; }
        footer .f-obx .r ul li div span {
          position: relative; }
          footer .f-obx .r ul li div span img {
            position: absolute;
            left: 0; }
      footer .f-obx .r ul li:not(:last-child) {
        margin-right: 50px; }
        @media (max-width: 1366px) {
          footer .f-obx .r ul li:not(:last-child) {
            margin-right: 25px; } }
        @media (max-width: 991px) {
          footer .f-obx .r ul li:not(:last-child) {
            margin-right: 10px; } }
        @media (max-width: 767px) {
          footer .f-obx .r ul li:not(:last-child) {
            margin-right: 0;
            margin-bottom: 13px; } }
      footer .f-obx .r ul li:nth-child(1) div span {
        padding-left: 33px; }
      footer .f-obx .r ul li:nth-child(1) div span img {
        width: 18px; }
      footer .f-obx .r ul li:nth-child(2) div span {
        padding-left: 38px; }
      footer .f-obx .r ul li:nth-child(2) div span img {
        width: 27px; }
      footer .f-obx .r ul li:nth-child(3) div span {
        padding-left: 27px; }
      footer .f-obx .r ul li:nth-child(3) div span img {
        width: 13px; }
    footer .f-obx .r .copyright {
      font-size: 0.875rem;
      line-height: 1.2;
      letter-spacing: 0.7px;
      font-weight: 400;
      color: #959595;
      font-family: "Noto Sans TC";
      text-align: right;
      margin-top: 20px; }
      @media (max-width: 767px) {
        footer .f-obx .r .copyright {
          text-align: center; } }
      footer .f-obx .r .copyright a {
        font-size: 0.875rem;
        line-height: 1.2;
        letter-spacing: 0.7px;
        font-weight: 400;
        color: #959595;
        font-family: "Noto Sans TC"; }

/******************************************/
/*		pages
/******************************************/
.indexPage .ind-bx {
  text-align: center;
  margin-top: 135px; }
  @media (max-width: 767px) {
    .indexPage .ind-bx {
      margin-top: 65px; } }
  .indexPage .ind-bx .in-ti {
    font-size: 4rem;
    line-height: 1.3;
    letter-spacing: 0.25px;
    font-weight: 700;
    color: #fff;
    text-shadow: black 0.1em 0.1em 0.2em; }
    @media (max-width: 767px) {
      .indexPage .ind-bx .in-ti {
        font-size: 1.9375rem;
        margin-bottom: 15px; } }
  .indexPage .ind-bx .in-des {
    font-size: 2.1875rem;
    line-height: 1.5;
    letter-spacing: 0.25px;
    font-weight: 500;
    color: #fff;
    text-shadow: black 0.1em 0.1em 0.2em; }
    @media (max-width: 767px) {
      .indexPage .ind-bx .in-des {
        font-size: 1.3125rem; } }

.indexPage .index-banner {
  position: fixed;
  top: 0;
  z-index: 0;
  height: 100vh;
  width: 100%;
  z-index: -1; }
  .indexPage .index-banner .slick-current.slick-active .bgcover {
    animation: banani 5.2s linear forwards; }
    @media (max-width: 767px) {
      .indexPage .index-banner .slick-current.slick-active .bgcover {
        animation: bananimo 5.2s linear forwards; } }

@keyframes banani {
  0% {
    transform: translateX(-50px); }
  100% {
    transform: translateX(0px); } }

@keyframes bananimo {
  0% {
    transform: translateX(-25px); }
  100% {
    transform: translateX(0px); } }
  .indexPage .index-banner .bgcover {
    width: calc(100% + 50px);
    height: 100vh;
    background-size: cover !important;
    background-position-y: 100px !important;
    transform: translateX(0px); }
    @media (max-width: 767px) {
      .indexPage .index-banner .bgcover {
        background-position-y: 70px !important;
        width: calc(100% + 25px); } }
  .indexPage .index-banner .slick-dots {
    width: 190px;
    height: 50px;
    position: absolute;
    margin: 0;
    left: 10vw;
    bottom: 5vh;
    pointer-events: none; }
    .indexPage .index-banner .slick-dots li {
      display: flex;
      width: 100%; }
    @media (max-width: 767px) {
      .indexPage .index-banner .slick-dots {
        width: 120px;
        bottom: 16vh;
        left: 6vw; } }
    .indexPage .index-banner .slick-dots li.slick-active {
      opacity: 1; }
      .indexPage .index-banner .slick-dots li.slick-active i::after {
        animation: dotani 5.3s linear forwards; }
        @media (max-width: 767px) {
          .indexPage .index-banner .slick-dots li.slick-active i::after {
            animation: dotanimo 5.3s linear forwards; } }

@keyframes dotani {
  0% {
    width: 0; }
  100% {
    width: 100px; } }

@keyframes dotanimo {
  0% {
    width: 0; }
  100% {
    width: 50px; } }
    .indexPage .index-banner .slick-dots li {
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 0.6px;
      font-weight: 300;
      color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      height: auto !important;
      opacity: 0;
      transition: .4s ease;
      font-family: "Noto Sans TC"; }
      .indexPage .index-banner .slick-dots li i {
        color: #f08300;
        padding-right: 120px;
        margin-right: 20px;
        position: relative; }
        @media (max-width: 767px) {
          .indexPage .index-banner .slick-dots li i {
            padding-right: 50px; } }
        .indexPage .index-banner .slick-dots li i::before {
          position: absolute;
          content: '';
          left: 36px;
          top: 60%;
          transform: translateY(-50%);
          width: 100px;
          height: 2px;
          background-color: #fff; }
          @media (max-width: 767px) {
            .indexPage .index-banner .slick-dots li i::before {
              left: 18px;
              width: 50px; } }
        .indexPage .index-banner .slick-dots li i::after {
          position: absolute;
          content: '';
          left: 36px;
          top: 60%;
          transform: translateY(-50%);
          height: 2px;
          background-color: #f08300; }
          @media (max-width: 767px) {
            .indexPage .index-banner .slick-dots li i::after {
              left: 18px; } }
      @media (max-width: 767px) {
        .indexPage .index-banner .slick-dots li {
          font-size: 0.8125rem; } }

.indexPage .item1 {
  padding: 0 54px;
  margin-top: 230px;
  padding-bottom: 10px;
  position: relative; }
  @media (max-width: 767px) {
    .indexPage .item1 {
      padding: 0 25px;
      padding-bottom: 10px;
      margin-top: 115px; } }
  .indexPage .item1::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10px;
    background-color: #f0f0f0; }
  .indexPage .item1 .it1-bx {
    background: #fff;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
    position: relative;
    padding-bottom: 80px;
    padding-top: 15px; }
    @media (max-width: 767px) {
      .indexPage .item1 .it1-bx {
        padding-top: 15px;
        padding-bottom: 35px; } }
    .indexPage .item1 .it1-bx::before {
      position: absolute;
      content: '';
      top: -94px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 95px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: #fff; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-bx::before {
          height: 43px;
          top: -42px; } }
    .indexPage .item1 .it1-bx::after {
      position: absolute;
      content: '';
      right: -50px;
      bottom: 0;
      background: url(../images/sketch01.png) no-repeat center center/contain;
      width: 496px;
      height: 520px;
      pointer-events: none;
      z-index: 0; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-bx::after {
          width: 246px;
          height: 270px; } }
    .indexPage .item1 .it1-bx .container {
      position: relative;
      z-index: 1; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-bx .container {
          padding: 0 15px; } }
  .indexPage .item1 .it1-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px; }
    @media (max-width: 767px) {
      .indexPage .item1 .it1-list {
        display: block;
        margin-bottom: 40px; } }
    .indexPage .item1 .it1-list li {
      width: 32.5%; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-list li {
          width: 100%; }
          .indexPage .item1 .it1-list li:not(:last-child) {
            margin-bottom: 40px; } }
      .indexPage .item1 .it1-list li .ti-bx {
        margin-bottom: 35px;
        text-align: center; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1-list li .ti-bx {
            margin-bottom: 18px; } }
        .indexPage .item1 .it1-list li .ti-bx .en-ti {
          font-size: 2rem;
          line-height: 1;
          letter-spacing: 3.2px;
          font-weight: 400;
          color: #f08300;
          margin-bottom: 10px; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1-list li .ti-bx .en-ti {
              font-size: 1.625rem; } }
        .indexPage .item1 .it1-list li .ti-bx .ti {
          font-size: 1.875rem;
          line-height: 1;
          letter-spacing: 3px;
          font-weight: 400;
          color: #000000; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1-list li .ti-bx .ti {
              font-size: 1.3125rem; } }
      .indexPage .item1 .it1-list li .it-obx {
        display: block;
        position: relative; }
        @media (min-width: 768px) {
          .indexPage .item1 .it1-list li .it-obx:hover .img-bx::before {
            opacity: 1; }
          .indexPage .item1 .it1-list li .it-obx:hover .des-bx {
            opacity: 1; } }
        .indexPage .item1 .it1-list li .it-obx .img-bx {
          border-radius: 10px;
          overflow: hidden;
          position: relative; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1-list li .it-obx .img-bx {
              border-radius: 5px; } }
          .indexPage .item1 .it1-list li .it-obx .img-bx::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            transition: .4s ease;
            opacity: 0; }
            @media (max-width: 767px) {
              .indexPage .item1 .it1-list li .it-obx .img-bx::before {
                opacity: 1; } }
        .indexPage .item1 .it1-list li .it-obx .des-bx {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 80%;
          overflow-y: auto;
          transition: .4s ease;
          opacity: 0; }
          @media (max-width: 767px) {
            .indexPage .item1 .it1-list li .it-obx .des-bx {
              opacity: 1; } }
          .indexPage .item1 .it1-list li .it-obx .des-bx .morimg {
            text-align: center;
            margin-bottom: 12px;
            display: block; }
          .indexPage .item1 .it1-list li .it-obx .des-bx .des {
            font-size: 1.25rem;
            line-height: 2;
            letter-spacing: 0.5px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            font-family: "Noto Sans TC";
            display: block; }
            @media (max-width: 767px) {
              .indexPage .item1 .it1-list li .it-obx .des-bx .des {
                font-size: 1rem;
                line-height: 1.8; } }
  .indexPage .item1 .sh-tagbx {
    margin-top: 60px;
    margin-bottom: 35px; }
    @media (max-width: 767px) {
      .indexPage .item1 .sh-tagbx {
        margin-top: 30px;
        margin-bottom: 30px; } }
  .indexPage .item1 .sear-btn {
    width: 193px;
    height: 56px;
    border-radius: 10px;
    background-color: #f08300;
    border: 1px solid #f08300;
    transition: .4s ease;
    display: block;
    margin: 0 auto;
    text-align: center; }
    @media (max-width: 767px) {
      .indexPage .item1 .sear-btn {
        width: 100%;
        border-radius: 5px; } }
    .indexPage .item1 .sear-btn span {
      position: relative;
      font-size: 1rem;
      line-height: 3.8;
      letter-spacing: 1px;
      font-weight: 400;
      color: #ffffff;
      padding-left: 32px;
      transition: .4s ease; }
      .indexPage .item1 .sear-btn span img {
        position: absolute;
        top: 42%;
        transform: translateY(-50%);
        left: 0;
        transition: .4s ease;
        width: 17px; }
      .indexPage .item1 .sear-btn span .unhove {
        opacity: 1; }
      .indexPage .item1 .sear-btn span .hove {
        opacity: 0; }
    @media (min-width: 768px) {
      .indexPage .item1 .sear-btn:hover {
        background-color: #fff; }
        .indexPage .item1 .sear-btn:hover span {
          color: #f08300; }
        .indexPage .item1 .sear-btn:hover .unhove {
          opacity: 0; }
        .indexPage .item1 .sear-btn:hover .hove {
          opacity: 1; } }

.indexPage .item2 {
  padding-top: 70px;
  background-color: #f0f0f0;
  position: relative; }
  @media (max-width: 767px) {
    .indexPage .item2 {
      padding-top: 30px; } }
  .indexPage .item2 .sh-tibx {
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .indexPage .item2 .sh-tibx {
        margin-bottom: 35px; } }
  .indexPage .item2 .it2-list li {
    width: 100%;
    position: relative; }
    .indexPage .item2 .it2-list li a {
      display: flex;
      width: 100%;
      position: relative; }
      .indexPage .item2 .it2-list li a::before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 56%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        z-index: 8;
        transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); }
      @media (min-width: 768px) {
        .indexPage .item2 .it2-list li a:hover::before {
          width: 100%;
          opacity: .7; }
        .indexPage .item2 .it2-list li a:hover .s-img .bgcover {
          opacity: 0; }
        .indexPage .item2 .it2-list li a:hover .s-img .tx-bx {
          opacity: 1;
          transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s; }
        .indexPage .item2 .it2-list li a:hover .b-img {
          opacity: 0; }
        .indexPage .item2 .it2-list li a:hover .all-img {
          opacity: 1; } }
    .indexPage .item2 .it2-list li .s-img {
      width: 44%;
      position: relative;
      z-index: 10; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-list li .s-img {
          width: 100%; } }
      .indexPage .item2 .it2-list li .s-img .bgcover {
        transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); }
      .indexPage .item2 .it2-list li .s-img .tx-bx {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12.2%;
        max-width: 296px;
        width: 100%;
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); }
        .indexPage .item2 .it2-list li .s-img .tx-bx .ti {
          font-size: 1.75rem;
          line-height: 1;
          letter-spacing: 1px;
          font-weight: 700;
          color: #fff;
          font-family: "Noto Sans TC";
          margin-bottom: 13px; }
        .indexPage .item2 .it2-list li .s-img .tx-bx .des {
          font-size: 1rem;
          line-height: 1.8;
          letter-spacing: 1px;
          font-weight: 400;
          color: #fff;
          height: 84px;
          position: relative;
          overflow: hidden;
          font-family: "Noto Sans TC"; }
    .indexPage .item2 .it2-list li .b-img {
      width: 56%;
      position: absolute;
      right: 0;
      z-index: 5;
      transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1); }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-list li .b-img {
          width: 0; } }
      .indexPage .item2 .it2-list li .b-img .bgcover {
        background-size: cover !important;
        padding-bottom: 41.1%; }
    .indexPage .item2 .it2-list li .all-img {
      position: absolute;
      right: 0;
      width: 100%;
      top: 0;
      transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
      z-index: 6;
      opacity: 0; }
      .indexPage .item2 .it2-list li .all-img .bgcover {
        padding-bottom: 22.5%; }
    .indexPage .item2 .it2-list li .bgcover {
      background-size: cover !important;
      padding-bottom: 51.1%; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-list li .bgcover {
          padding-bottom: 51.8%; } }
    .indexPage .item2 .it2-list li:nth-child(even) .s-img {
      margin-left: auto; }
    .indexPage .item2 .it2-list li:nth-child(even) .b-img {
      left: 0; }

.keywordPage .item1 .container {
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .keywordPage .item1 .container {
      max-width: 1056px; } }

@media (max-width: 767px) {
  .keywordPage .item1 .it1-bx {
    padding-top: 15px; } }

.keywordPage .item1 .sh-tibx {
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    .keywordPage .item1 .sh-tibx {
      margin-bottom: 43px; } }

.keywordPage .item1 .flex-bx {
  display: flex;
  justify-content: space-between;
  padding-bottom: 110px; }
  @media (max-width: 767px) {
    .keywordPage .item1 .flex-bx {
      padding-bottom: 55px;
      display: block; } }
  .keywordPage .item1 .flex-bx .t-bx {
    width: 29%;
    order: 2;
    margin-top: 42px; }
    @media (max-width: 767px) {
      .keywordPage .item1 .flex-bx .t-bx {
        width: 100%;
        margin-top: 0;
        margin-bottom: 20px; } }
    .keywordPage .item1 .flex-bx .t-bx .sear-btn {
      width: 100%;
      height: 56px;
      border-radius: 10px;
      background-color: #f08300;
      border: 1px solid #f08300;
      transition: .4s ease;
      display: block;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 40px; }
      @media (max-width: 767px) {
        .keywordPage .item1 .flex-bx .t-bx .sear-btn {
          width: 100%;
          border-radius: 5px;
          margin-bottom: 20px;
          height: 51px; } }
      .keywordPage .item1 .flex-bx .t-bx .sear-btn span {
        position: relative;
        font-size: 1rem;
        line-height: 3.8;
        letter-spacing: 1px;
        font-weight: 400;
        color: #ffffff;
        padding-left: 32px;
        transition: .4s ease; }
        @media (max-width: 767px) {
          .keywordPage .item1 .flex-bx .t-bx .sear-btn span {
            line-height: 3.6; } }
        .keywordPage .item1 .flex-bx .t-bx .sear-btn span img {
          position: absolute;
          top: 42%;
          transform: translateY(-50%);
          left: 0;
          transition: .4s ease;
          width: 17px; }
        .keywordPage .item1 .flex-bx .t-bx .sear-btn span .unhove {
          opacity: 1; }
        .keywordPage .item1 .flex-bx .t-bx .sear-btn span .hove {
          opacity: 0; }
      @media (min-width: 768px) {
        .keywordPage .item1 .flex-bx .t-bx .sear-btn:hover {
          background-color: #fff; }
          .keywordPage .item1 .flex-bx .t-bx .sear-btn:hover span {
            color: #f08300; }
          .keywordPage .item1 .flex-bx .t-bx .sear-btn:hover .unhove {
            opacity: 0; }
          .keywordPage .item1 .flex-bx .t-bx .sear-btn:hover .hove {
            opacity: 1; } }
  .keywordPage .item1 .flex-bx .b-bx {
    width: 65%;
    order: 1; }
    @media (max-width: 767px) {
      .keywordPage .item1 .flex-bx .b-bx {
        width: 100%; } }
    @media (max-width: 767px) {
      .keywordPage .item1 .flex-bx .b-bx .sh-areabx:not(:last-child) {
        margin-bottom: 30px; } }

.aboutpg footer {
  z-index: 24; }

.aboutPage .item1 {
  padding: 0 60px;
  background: none;
  padding-bottom: 140px; }
  @media (max-width: 767px) {
    .aboutPage .item1 {
      padding: 0 25px;
      padding-bottom: 110px; } }
  @media (min-width: 1199px) {
    .aboutPage .item1 .container {
      max-width: 1056px; } }
  @media (max-width: 767px) {
    .aboutPage .item1 .container {
      padding: 0 15px; } }
  .aboutPage .item1 .it1-bx {
    position: relative; }
    .aboutPage .item1 .it1-bx .bg1 {
      position: absolute;
      top: -2.5%;
      right: 0; }
      @media (max-width: 991px) {
        .aboutPage .item1 .it1-bx .bg1 {
          width: 33%;
          top: -0.5%; } }
      @media (max-width: 767px) {
        .aboutPage .item1 .it1-bx .bg1 {
          width: 50%;
          top: -1%;
          z-index: 0; } }
    .aboutPage .item1 .it1-bx .sh-tibx {
      position: relative;
      z-index: 10; }
  .aboutPage .item1 .flex-bx {
    margin-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .aboutPage .item1 .flex-bx {
        display: block; } }
    @media (max-width: 767px) {
      .aboutPage .item1 .flex-bx {
        margin-top: 45px;
        padding-bottom: 40px; } }
    .aboutPage .item1 .flex-bx .l {
      width: 400px;
      height: 380px;
      position: relative; }
      @media (max-width: 991px) {
        .aboutPage .item1 .flex-bx .l {
          margin: 0 auto;
          margin-bottom: 40px; } }
      @media (max-width: 767px) {
        .aboutPage .item1 .flex-bx .l {
          width: 100%;
          max-width: 300px;
          height: 290px; } }
      @media (max-width: 350px) {
        .aboutPage .item1 .flex-bx .l {
          height: 235px; } }
      .aboutPage .item1 .flex-bx .l .cirlce {
        width: calc(50% + 19px);
        height: calc(50% + 34px);
        position: absolute; }
        @media (max-width: 767px) {
          .aboutPage .item1 .flex-bx .l .cirlce {
            width: calc(50% + 15px);
            height: calc(50% + 23px); } }
        @media (max-width: 350px) {
          .aboutPage .item1 .flex-bx .l .cirlce {
            width: calc(50% + 12px); } }
        .aboutPage .item1 .flex-bx .l .cirlce .des {
          font-size: 1.375rem;
          line-height: 1;
          letter-spacing: 2.2px;
          font-weight: 400;
          color: #f08300;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          opacity: 0;
          animation: lani .5s .7s ease-in-out forwards; }
          @media (max-width: 767px) {
            .aboutPage .item1 .flex-bx .l .cirlce .des {
              font-size: 0.9375rem; } }
          @media (max-width: 350px) {
            .aboutPage .item1 .flex-bx .l .cirlce .des {
              font-size: 0.75rem; } }
      .aboutPage .item1 .flex-bx .l .cirl1 {
        top: 0;
        left: 50%;
        transform: translateX(-50%); }
      .aboutPage .item1 .flex-bx .l .cirl2 {
        bottom: 0;
        left: 0; }
        .aboutPage .item1 .flex-bx .l .cirl2 svg {
          transform: rotate(-120deg); }
        .aboutPage .item1 .flex-bx .l .cirl2::before {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          background: url(../images/about/leaf-01.png) no-repeat center center/contain;
          width: 127px;
          height: 62px;
          z-index: 1;
          opacity: 0;
          animation: lani .5s .3s ease-in forwards; }
          @media (max-width: 767px) {
            .aboutPage .item1 .flex-bx .l .cirl2::before {
              width: 56%;
              height: 44px; } }
          @media (max-width: 350px) {
            .aboutPage .item1 .flex-bx .l .cirl2::before {
              width: 46%;
              height: 46px;
              right: 10px;
              top: -5px; } }
        .aboutPage .item1 .flex-bx .l .cirl2::after {
          position: absolute;
          content: '';
          top: 50%;
          background: url(../images/about/leaf-01.png) no-repeat center center/contain;
          z-index: 1;
          transform: translateY(-50%) rotate(-120deg);
          right: -45px;
          height: 60px;
          width: 128px;
          opacity: 0;
          animation: lani .5s .3s ease-in forwards; }
          @media (max-width: 767px) {
            .aboutPage .item1 .flex-bx .l .cirl2::after {
              width: 56%;
              height: 44px;
              right: -30px; } }
          @media (max-width: 350px) {
            .aboutPage .item1 .flex-bx .l .cirl2::after {
              width: 45%;
              height: 41px;
              right: -17px; } }
      .aboutPage .item1 .flex-bx .l .cirl3 {
        bottom: 0;
        right: 0; }
        .aboutPage .item1 .flex-bx .l .cirl3 svg {
          transform: rotate(120deg); }
        .aboutPage .item1 .flex-bx .l .cirl3::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          background: url(../images/about/leaf-01.png) no-repeat center center/contain;
          width: 127px;
          height: 62px;
          z-index: 1;
          transform: rotate(-60deg);
          opacity: 0;
          animation: lani .5s .3s ease-in forwards; }
          @media (max-width: 767px) {
            .aboutPage .item1 .flex-bx .l .cirl3::before {
              width: 56%;
              height: 44px; } }
          @media (max-width: 350px) {
            .aboutPage .item1 .flex-bx .l .cirl3::before {
              width: 46%;
              height: 46px;
              left: 10px;
              top: -4px; } }

@keyframes lani {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
      .aboutPage .item1 .flex-bx .l .st0 {
        fill: none;
        stroke: #F08300;
        stroke-width: 59.8741;
        stroke-miterlimit: 10; }
      .aboutPage .item1 .flex-bx .l .osWLjzkr_0 {
        stroke-dasharray: 861 863;
        stroke-dashoffset: 862;
        animation: osWLjzkr_draw 1000ms ease-in-out 0ms forwards; }

@keyframes osWLjzkr_draw {
  100% {
    stroke-dashoffset: 0; } }

@keyframes osWLjzkr_fade {
  0% {
    stroke-opacity: 1; }
  94.44444444444444% {
    stroke-opacity: 1; }
  100% {
    stroke-opacity: 0; } }
    .aboutPage .item1 .flex-bx .r {
      margin-left: 8.5vw;
      position: relative;
      overflow: hidden; }
      @media (max-width: 991px) {
        .aboutPage .item1 .flex-bx .r {
          width: 100%; } }
      @media (max-width: 767px) {
        .aboutPage .item1 .flex-bx .r {
          margin-left: 0; } }
      .aboutPage .item1 .flex-bx .r .ti {
        font-size: 2.25rem;
        line-height: 1.2;
        letter-spacing: 1.8px;
        font-weight: 700;
        color: #f08300;
        margin-bottom: 25px;
        left: -100%;
        position: relative;
        animation: desani 1s 1s cubic-bezier(0.86, 0, 0.07, 1) forwards; }
        @media (max-width: 767px) {
          .aboutPage .item1 .flex-bx .r .ti {
            font-size: 1.625rem; } }
      .aboutPage .item1 .flex-bx .r .mb.des {
        margin-bottom: 18px;
        animation: desani 1s 1.2s cubic-bezier(0.86, 0, 0.07, 1) forwards; }
      .aboutPage .item1 .flex-bx .r .des {
        font-size: 1.875rem;
        line-height: 1;
        letter-spacing: 3px;
        font-weight: 400;
        color: #ffffff;
        background-color: #000000;
        padding: 18px 10px 9px 20px;
        display: inline-block;
        left: -100%;
        position: relative;
        animation: desani 1s 1.8s cubic-bezier(0.86, 0, 0.07, 1) forwards; }
        @media (max-width: 767px) {
          .aboutPage .item1 .flex-bx .r .des {
            font-size: 1.3125rem;
            padding: 14px 4px 6px 15px; } }

@keyframes desani {
  0% {
    left: -100%; }
  100% {
    left: 0; } }
  .aboutPage .item1 .pro-list {
    display: flex; }
    .aboutPage .item1 .pro-list .l {
      width: 10.5%;
      position: relative; }
      @media (max-width: 767px) {
        .aboutPage .item1 .pro-list .l {
          display: none; } }
      .aboutPage .item1 .pro-list .l .img-bx {
        position: absolute;
        top: -10px;
        transition: opacity .1s ease-in-out; }
      .aboutPage .item1 .pro-list .l .fix.img-bx {
        position: fixed;
        top: 400px; }
      .aboutPage .item1 .pro-list .l .fix.img-bx.change {
        position: absolute;
        top: auto;
        bottom: 42.5%; }
    .aboutPage .item1 .pro-list .r {
      width: 89.5%; }
      @media (max-width: 767px) {
        .aboutPage .item1 .pro-list .r {
          width: 100%; } }
      .aboutPage .item1 .pro-list .r .pro-ti {
        font-size: 2rem;
        line-height: 1;
        letter-spacing: 3.2px;
        font-weight: 400;
        color: #f08300; }
        @media (max-width: 767px) {
          .aboutPage .item1 .pro-list .r .pro-ti {
            font-size: 1.625rem;
            padding-left: 34px;
            position: relative; } }
        .aboutPage .item1 .pro-list .r .pro-ti span {
          font-size: 1rem;
          line-height: 1.2;
          letter-spacing: 1px;
          font-weight: 400;
          color: #000000; }
          @media (max-width: 767px) {
            .aboutPage .item1 .pro-list .r .pro-ti span {
              font-size: 0.875rem; } }
        .aboutPage .item1 .pro-list .r .pro-ti .huimg {
          position: absolute;
          left: 0;
          top: 0;
          width: 25px; }
      .aboutPage .item1 .pro-list .r .pro-des {
        font-size: 1rem;
        line-height: 1.8;
        letter-spacing: 1.6px;
        font-weight: 400;
        color: #000000;
        font-family: "Noto Sans TC"; }
        @media (max-width: 767px) {
          .aboutPage .item1 .pro-list .r .pro-des {
            font-size: 0.875rem;
            line-height: 1.6; } }
      .aboutPage .item1 .pro-list .r .in-bx {
        margin-bottom: 80px; }
        @media (max-width: 767px) {
          .aboutPage .item1 .pro-list .r .in-bx {
            margin-bottom: 40px; } }
        .aboutPage .item1 .pro-list .r .in-bx .img-bx {
          width: 72px;
          margin-top: 30px;
          margin-bottom: 25px; }
          @media (max-width: 767px) {
            .aboutPage .item1 .pro-list .r .in-bx .img-bx {
              width: 60px;
              margin-top: 15px;
              margin-bottom: 21px; } }
        .aboutPage .item1 .pro-list .r .in-bx .des-ti {
          font-size: 1.25rem;
          line-height: 1.5;
          letter-spacing: 2px;
          font-weight: 700;
          color: #000000;
          font-family: "Noto Sans TC"; }
          @media (max-width: 767px) {
            .aboutPage .item1 .pro-list .r .in-bx .des-ti {
              font-size: 1rem;
              margin-bottom: 10px; } }
      .aboutPage .item1 .pro-list .r .rel-bx {
        margin-bottom: 80px; }
        @media (max-width: 767px) {
          .aboutPage .item1 .pro-list .r .rel-bx {
            margin-bottom: 45px; } }
        .aboutPage .item1 .pro-list .r .rel-bx .img-bx {
          width: 75px;
          margin-top: 20px;
          margin-bottom: 30px; }
          @media (max-width: 767px) {
            .aboutPage .item1 .pro-list .r .rel-bx .img-bx {
              width: 71px; } }
        .aboutPage .item1 .pro-list .r .rel-bx .proul li {
          display: flex;
          align-items: center; }
          .aboutPage .item1 .pro-list .r .rel-bx .proul li:not(:last-child) {
            margin-bottom: 30px; }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .rel-bx .proul li:not(:last-child) {
                margin-bottom: 15px; } }
          .aboutPage .item1 .pro-list .r .rel-bx .proul li .circle {
            min-width: 63px;
            width: 63px;
            height: 63px;
            background-color: #e58012;
            font-size: 1.25rem;
            line-height: 3.5;
            letter-spacing: 1px;
            font-weight: 700;
            color: #fff;
            border-radius: 50%;
            margin-right: 14px;
            text-align: center; }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .rel-bx .proul li .circle {
                min-width: 49px;
                width: 49px;
                height: 49px;
                font-size: 1rem; } }
          .aboutPage .item1 .pro-list .r .rel-bx .proul li .prd-des {
            width: calc(100% - 77px); }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .rel-bx .proul li .prd-des {
                width: calc(100% - 63px); } }
      .aboutPage .item1 .pro-list .r .fa-bx {
        padding-bottom: 90px; }
        @media (max-width: 767px) {
          .aboutPage .item1 .pro-list .r .fa-bx {
            padding-bottom: 30px; } }
        .aboutPage .item1 .pro-list .r .fa-bx .img-bx {
          width: 53px;
          margin-top: 30px;
          margin-bottom: 30px; }
          @media (max-width: 767px) {
            .aboutPage .item1 .pro-list .r .fa-bx .img-bx {
              margin-top: 25px;
              margin-bottom: 25px; } }
        .aboutPage .item1 .pro-list .r .fa-bx .proul li {
          display: flex;
          align-items: center; }
          .aboutPage .item1 .pro-list .r .fa-bx .proul li:not(:last-child) {
            margin-bottom: 30px; }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .fa-bx .proul li:not(:last-child) {
                margin-bottom: 15px; } }
          .aboutPage .item1 .pro-list .r .fa-bx .proul li .circle {
            min-width: 63px;
            width: 63px;
            height: 63px;
            background-color: #e58012;
            font-size: 1.25rem;
            line-height: 3.5;
            letter-spacing: 1px;
            font-weight: 700;
            color: #fff;
            border-radius: 50%;
            margin-right: 14px;
            text-align: center; }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .fa-bx .proul li .circle {
                min-width: 49px;
                width: 49px;
                height: 49px;
                font-size: 1rem; } }
          .aboutPage .item1 .pro-list .r .fa-bx .proul li .d-bx {
            width: calc(100% - 77px); }
            @media (max-width: 767px) {
              .aboutPage .item1 .pro-list .r .fa-bx .proul li .d-bx {
                width: calc(100% - 63px); } }
            .aboutPage .item1 .pro-list .r .fa-bx .proul li .d-bx .d-ti {
              font-size: 1rem;
              line-height: 1.8;
              letter-spacing: 1.6px;
              font-weight: 700;
              color: #000000;
              position: relative;
              padding-right: 10px;
              display: inline-block; }
              .aboutPage .item1 .pro-list .r .fa-bx .proul li .d-bx .d-ti::before {
                position: absolute;
                content: '';
                right: 0;
                top: 4px;
                width: 1px;
                height: 16px;
                background: #000000; }

@media (min-width: 1200px) {
  .newsPage .container {
    max-width: 1056px; } }

@media (max-width: 767px) {
  .newsPage .container {
    padding: 0 15px; } }

.newsPage .item1 {
  padding: 0 60px;
  background: none;
  padding-bottom: 200px; }
  @media (max-width: 767px) {
    .newsPage .item1 {
      padding: 0 25px;
      padding-bottom: 100px; } }

@media (max-width: 767px) {
  .newsPage .it1-bx {
    padding-top: 0px;
    padding-bottom: 40px; } }

.newsPage .it1-bx .it-bgbx {
  position: relative;
  overflow: hidden; }

.newsPage .search-bx {
  margin-top: 75px;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 57px; }
  @media (max-width: 767px) {
    .newsPage .search-bx {
      border-radius: 5px;
      margin-top: 46px;
      margin-bottom: 35px; } }
  .newsPage .search-bx .l {
    width: calc(100% - 150px); }
    @media (max-width: 767px) {
      .newsPage .search-bx .l {
        width: calc(100% - 70px); } }
    .newsPage .search-bx .l input {
      background-color: #fef1ea;
      height: 100%;
      padding-left: 33px;
      border-radius: 5px 0 0 5px;
      font-size: 1.25rem;
      line-height: 1.2;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000000; }
      @media (max-width: 767px) {
        .newsPage .search-bx .l input {
          padding-left: 16px;
          font-size: 1rem; } }
  .newsPage .search-bx .r {
    width: 150px;
    height: 100%; }
    @media (max-width: 767px) {
      .newsPage .search-bx .r {
        width: 70px; } }
    .newsPage .search-bx .r a {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #f08300;
      position: relative; }
    .newsPage .search-bx .r .send {
      display: block; }
      .newsPage .search-bx .r .send img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px; }
    .newsPage .search-bx .r .close {
      display: none; }
      .newsPage .search-bx .r .close::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 2px;
        height: 25px;
        background: #fff; }
      .newsPage .search-bx .r .close::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 2px;
        height: 25px;
        background: #fff; }
  .newsPage .search-bx .r.active .send {
    display: none; }
  .newsPage .search-bx .r.active .close {
    display: block; }

.newsPage .b-card {
  padding-bottom: 80px;
  position: relative;
  z-index: 5; }
  @media (max-width: 991px) {
    .newsPage .b-card {
      padding-bottom: 40px; } }
  @media (max-width: 767px) {
    .newsPage .b-card {
      padding-bottom: 10px; } }
  .newsPage .b-card a {
    display: flex;
    align-items: center;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    border: 2px solid white; }
    @media (max-width: 991px) {
      .newsPage .b-card a {
        display: block;
        border: none;
        border-radius: 5px; } }
    @media (min-width: 768px) {
      .newsPage .b-card a:hover {
        border: 2px solid #f08300; }
        .newsPage .b-card a:hover .l .bgcover {
          transform: scale(1.2); }
        .newsPage .b-card a:hover .r .ti::before {
          width: 100%; }
        .newsPage .b-card a:hover .more-btn {
          background-color: #f08300;
          color: #fff; } }
  .newsPage .b-card .l {
    width: 59.5%;
    overflow: hidden;
    position: relative; }
    @media (max-width: 991px) {
      .newsPage .b-card .l {
        width: 100%; } }
    .newsPage .b-card .l .bgcover {
      padding-bottom: 69.7%;
      background-size: cover !important;
      transition: .6s ease; }
  .newsPage .b-card .r {
    width: 40.5%;
    padding: 0 4.5%; }
    @media (max-width: 991px) {
      .newsPage .b-card .r {
        width: 100%;
        padding: 0 10px;
        padding-bottom: 18px;
        background-color: #fff; } }
    .newsPage .b-card .r .da {
      font-size: 1.25rem;
      line-height: 1.2;
      letter-spacing: 1px;
      font-weight: 400;
      color: #f08300;
      margin-bottom: 8px; }
      @media (max-width: 991px) {
        .newsPage .b-card .r .da {
          margin-top: 10px;
          margin-bottom: 2px; } }
      @media (max-width: 767px) {
        .newsPage .b-card .r .da {
          font-size: 1rem;
          margin-bottom: 2px; } }
    .newsPage .b-card .r .ti {
      font-size: 1.375rem;
      line-height: 1.64;
      letter-spacing: 2.2px;
      font-weight: 700;
      color: #000000;
      position: relative;
      height: 3.28em;
      line-height: 1.64;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      height: auto;
      max-height: 3.28em;
      padding-bottom: 3px;
      margin-bottom: 10px;
      font-family: "Noto Sans TC"; }
      .newsPage .b-card .r .ti::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 2px;
        width: 0;
        height: 3px;
        background: #f08300;
        transition: .6s ease; }
      @media (max-width: 991px) {
        .newsPage .b-card .r .ti {
          margin-bottom: 5px; } }
      @media (max-width: 767px) {
        .newsPage .b-card .r .ti {
          font-size: 1rem; } }
    .newsPage .b-card .r .des {
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 1.6px;
      font-weight: 400;
      color: #000000;
      height: 9.4em;
      line-height: 1.88;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      padding-bottom: 10px;
      font-family: "Noto Sans TC"; }
      @media (max-width: 991px) {
        .newsPage .b-card .r .des {
          height: auto; } }
      @media (max-width: 767px) {
        .newsPage .b-card .r .des {
          font-size: 0.875rem; } }
    .newsPage .b-card .r .more-btn {
      font-size: 1rem;
      line-height: 1.2;
      letter-spacing: 0.8px;
      font-weight: 400;
      color: #000;
      padding: 12px 17px;
      border: 1px solid #f08300;
      border-radius: 10px;
      background-color: #ffffff;
      font-family: "Noto Sans TC";
      transition: .4s ease;
      display: block;
      width: 125px;
      margin: 0;
      text-align: center; }
      @media (max-width: 767px) {
        .newsPage .b-card .r .more-btn {
          font-size: 0.875rem;
          padding: 10px 13px;
          border-radius: 5px;
          width: 102px; } }

.newsPage .news-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  margin-top: 35px; }
  @media (max-width: 767px) {
    .newsPage .news-list {
      display: block;
      margin-top: 25px; } }
  .newsPage .news-list::before {
    position: absolute;
    content: '';
    background: url(../images/news/pc_bg-1.png) no-repeat center center/cover;
    width: 1656px;
    height: 365px;
    z-index: 0;
    top: -100px;
    right: -378px; }
    @media (max-width: 767px) {
      .newsPage .news-list::before {
        display: none; } }
  .newsPage .news-list::after {
    position: absolute;
    content: '';
    background: url(../images/news/pc_bg-2.png) no-repeat center center/cover;
    width: 790px;
    height: 324px;
    z-index: 0;
    bottom: 20%;
    left: -378px; }
    @media (max-width: 767px) {
      .newsPage .news-list::after {
        display: none; } }
  .newsPage .news-list li {
    width: 46%;
    margin-bottom: 110px;
    position: relative;
    z-index: 1; }
    @media (max-width: 767px) {
      .newsPage .news-list li {
        width: 100%;
        margin-bottom: 35px;
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18); } }
    .newsPage .news-list li a {
      display: block; }
      @media (min-width: 768px) {
        .newsPage .news-list li a:hover .img-bx::before {
          opacity: 1; }
        .newsPage .news-list li a:hover .img-bx .more-btn {
          opacity: 1; }
        .newsPage .news-list li a:hover .ti::before {
          width: 100%; } }
      @media (max-width: 767px) {
        .newsPage .news-list li a {
          padding-bottom: 18px; } }
      .newsPage .news-list li a .img-bx {
        position: relative;
        border-radius: 10px;
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
        overflow: hidden;
        transition: .4 ease; }
        @media (max-width: 767px) {
          .newsPage .news-list li a .img-bx {
            border-radius: 5px;
            box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
            border-radius: 5px 5px 0 0; } }
        .newsPage .news-list li a .img-bx::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(34, 24, 21, 0.7);
          transition: .4s;
          opacity: 0; }
        .newsPage .news-list li a .img-bx .more-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.8px;
          font-weight: 400;
          color: #fff;
          font-family: "Noto Sans TC";
          transition: .4s;
          opacity: 0; }
          .newsPage .news-list li a .img-bx .more-btn img {
            position: relative;
            top: -3px;
            margin-right: 10px; }
      .newsPage .news-list li a .da {
        font-size: 1.25rem;
        line-height: 1.2;
        letter-spacing: 1px;
        font-weight: 400;
        color: #f08300;
        margin-bottom: 8px;
        margin-top: 19px; }
        @media (max-width: 767px) {
          .newsPage .news-list li a .da {
            font-size: 1rem;
            margin-bottom: 2px;
            margin-top: 14px;
            padding: 0 10px; } }
      .newsPage .news-list li a .ti {
        font-size: 1.375rem;
        line-height: 1.64;
        letter-spacing: 2.2px;
        font-weight: 700;
        color: #000000;
        position: relative;
        height: 3.28em;
        line-height: 1.64;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 第幾行要顯示點點點 */
        -webkit-box-orient: vertical;
        height: auto;
        max-height: 3.28em;
        padding-bottom: 3px;
        margin-bottom: 10px;
        margin-top: 5px;
        font-family: "Noto Sans TC"; }
        .newsPage .news-list li a .ti::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 2px;
          width: 0;
          height: 3px;
          background: #f08300;
          transition: .6s ease; }
        @media (max-width: 767px) {
          .newsPage .news-list li a .ti {
            margin-top: 2px;
            margin-bottom: 5px;
            font-size: 1rem;
            padding: 0 10px; } }
      .newsPage .news-list li a .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 1.6px;
        font-weight: 400;
        color: #000000;
        height: 5.64em;
        line-height: 1.88;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* 第幾行要顯示點點點 */
        -webkit-box-orient: vertical;
        padding-bottom: 10px;
        font-family: "Noto Sans TC";
        max-height: 5.64em;
        height: auto; }
        @media (max-width: 767px) {
          .newsPage .news-list li a .des {
            font-size: 0.875rem;
            padding: 0 10px; } }
      .newsPage .news-list li a .more-btn2 {
        font-size: 1rem;
        line-height: 1.2;
        letter-spacing: 0.8px;
        font-weight: 400;
        color: #000;
        padding: 12px 17px;
        border: 1px solid #f08300;
        border-radius: 10px;
        background-color: #ffffff;
        font-family: "Noto Sans TC";
        transition: .4s ease;
        width: 125px;
        margin: 0;
        text-align: center; }
        @media (max-width: 767px) {
          .newsPage .news-list li a .more-btn2 {
            font-size: 0.875rem;
            padding: 10px 13px;
            border-radius: 5px;
            width: 102px;
            margin-top: 10px;
            margin-left: 10px; } }

.newsPage .page {
  padding-bottom: 80px; }
  @media (max-width: 767px) {
    .newsPage .page {
      padding-bottom: 0px; } }

@media (min-width: 1200px) {
  .newsdetailPage .container {
    max-width: 1056px; } }

@media (max-width: 767px) {
  .newsdetailPage .container {
    padding: 0 15px; } }

.newsdetailPage .item1 {
  padding: 0 60px;
  padding-bottom: 200px;
  background: none; }
  @media (max-width: 767px) {
    .newsdetailPage .item1 {
      padding: 0 25px;
      padding-bottom: 100px; } }
  .newsdetailPage .item1 .it1-bx {
    padding-bottom: 50px; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .it1-bx {
        padding-bottom: 25px; } }
  .newsdetailPage .item1 .ti-bx {
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .ti-bx {
        margin-bottom: 15px; } }
  .newsdetailPage .item1 .ti-bx .da {
    font-size: 1.25rem;
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 400;
    color: #f08300;
    margin-bottom: 2px; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .ti-bx .da {
        font-size: 1rem;
        margin-bottom: 1px; } }
  .newsdetailPage .item1 .ti-bx .ti {
    font-size: 1.375rem;
    line-height: 1.64;
    letter-spacing: 2.2px;
    font-weight: 700;
    color: #000000;
    position: relative;
    height: 3.28em;
    line-height: 1.64;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical;
    height: auto;
    max-height: 3.28em;
    padding-bottom: 1px;
    font-family: "Noto Sans TC"; }
    .newsdetailPage .item1 .ti-bx .ti::before {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      width: 25px;
      height: 3px;
      background: #f08300;
      z-index: 3; }
    .newsdetailPage .item1 .ti-bx .ti::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: #dcdddd; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .ti-bx .ti {
        font-size: 1rem; } }
  .newsdetailPage .item1 .editor_Content {
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .editor_Content {
        margin-bottom: 40px; } }
  .newsdetailPage .item1 .back-btn {
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #000;
    padding: 12px 17px;
    border: 1px solid #f08300;
    border-radius: 10px;
    background-color: #ffffff;
    font-family: "Noto Sans TC";
    transition: .4s ease;
    display: block;
    width: 125px;
    margin: 0 auto;
    margin-bottom: 140px;
    text-align: center; }
    @media (min-width: 768px) {
      .newsdetailPage .item1 .back-btn:hover {
        background-color: #f08300;
        color: #fff; } }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .back-btn {
        font-size: 0.875rem;
        padding: 10px 13px;
        border-radius: 5px;
        margin-bottom: 100px;
        width: 102px; } }

.tfmb footer {
  z-index: 8; }
  @media (max-width: 767px) {
    .tfmb footer {
      margin-bottom: 100px; } }

.fix.rf-arbx {
  right: 15.2%; }
  @media (min-width: 768px) {
    .fix.rf-arbx {
      position: fixed;
      top: 100px; } }
  @media (max-width: 1499px) {
    .fix.rf-arbx {
      right: 3.6%; } }
  @media (max-width: 1099px) {
    .fix.rf-arbx {
      right: 5%; } }
  @media (max-width: 991px) {
    .fix.rf-arbx {
      right: 5.4%; } }
  @media (max-width: 767px) {
    .fix.rf-arbx {
      right: auto; } }

.rf-arbx {
  position: absolute;
  top: 160px;
  right: 15.2%;
  box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  overflow: hidden;
  z-index: 50; }
  @media (max-width: 1499px) {
    .rf-arbx {
      right: 3.6%; } }
  @media (max-width: 1099px) {
    .rf-arbx {
      right: 5%; } }
  @media (max-width: 991px) {
    .rf-arbx {
      right: 5.4%; } }
  @media (max-width: 767px) {
    .rf-arbx {
      position: fixed;
      bottom: 50px;
      top: auto;
      right: auto;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 0px; } }
  .rf-arbx a {
    width: 47px;
    height: 80px;
    display: block;
    background: #fff;
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 2px;
    font-weight: 400;
    color: #000;
    transition: .4s ease;
    writing-mode: vertical-lr;
    text-align: center; }
    @media (min-width: 768px) {
      .rf-arbx a:hover {
        color: #fff;
        background-color: #f08300; } }
    @media (max-width: 767px) {
      .rf-arbx a {
        width: 33.3333%;
        height: 50px;
        color: #fff;
        background-color: #deaf6c;
        line-height: 3.5;
        writing-mode: inherit;
        font-size: 1rem; }
        .rf-arbx a:not(:last-child) {
          border-right: 1px solid #fff; } }

@media (max-width: 767px) {
  .casesPage .top_btn {
    bottom: 110px; } }

@media (max-width: 767px) {
  .casesPage .top_btn.fix {
    bottom: 30px; } }

.casesPage .container {
  margin: 0 auto;
  position: relative;
  z-index: 5; }
  @media (min-width: 1200px) {
    .casesPage .container {
      max-width: 1056px; } }

.casesPage .sh-tibx {
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    .casesPage .sh-tibx {
      margin-bottom: 40px; } }

.casesPage .it1-bx {
  margin-bottom: 110px;
  position: relative; }
  .casesPage .it1-bx .bg1 {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../images/cases/bg-1.png) no-repeat center center/contain;
    width: 875px;
    height: 365px;
    pointer-events: none;
    z-index: 0; }
    @media (max-width: 767px) {
      .casesPage .it1-bx .bg1 {
        bottom: 13%;
        width: 487px;
        height: 215px;
        right: 2px; } }
  @media (max-width: 767px) {
    .casesPage .it1-bx {
      margin-bottom: 90px; } }
  .casesPage .it1-bx .ar-obx {
    position: relative; }
    .casesPage .it1-bx .ar-obx .sh-area3bx:not(:last-child) {
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        .casesPage .it1-bx .ar-obx .sh-area3bx:not(:last-child) {
          margin-bottom: 30px; } }

.classicPage .sh-area3bx ul li a .des-bx .thx {
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 1px;
  font-weight: 700;
  color: #c99e38;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -18px;
  position: relative; }

.classicPage .sh-area3bx ul li a .des-bx span {
  display: block;
  margin-top: 6px; }

.classicPage .sh-area3bx ul li a .des-bx img {
  display: block; }

.classicPage .item1 .it1-bx .ar-obx .bg1 {
  position: absolute;
  top: 100px;
  left: -438px;
  background: none; }
  @media (max-width: 767px) {
    .classicPage .item1 .it1-bx .ar-obx .bg1 {
      left: -37px;
      width: 19%;
      top: -90px; } }

.classicPage .item1 .it1-bx .ar-obx .bg2 {
  position: absolute;
  top: 265px;
  right: -435px; }
  @media (max-width: 767px) {
    .classicPage .item1 .it1-bx .ar-obx .bg2 {
      right: -31px;
      width: 30%;
      top: 245px;
      z-index: -1; } }

.classicPage .item1 .it1-bx .ar-obx .bg3 {
  position: absolute;
  bottom: 42%;
  right: -435px; }
  @media (max-width: 767px) {
    .classicPage .item1 .it1-bx .ar-obx .bg3 {
      right: -27px;
      width: 16%;
      bottom: 40%; } }

.classicPage .item1 .it1-bx .ar-obx .bg4 {
  position: absolute;
  bottom: 0;
  right: -435px; }
  @media (max-width: 767px) {
    .classicPage .item1 .it1-bx .ar-obx .bg4 {
      right: -26px;
      width: 152%;
      z-index: -1;
      bottom: 14%; } }

.classicPage .item1 .it1-bx .ar-obx .bg5 {
  position: absolute;
  bottom: 0;
  left: -435px; }

@media (min-width: 768px) {
  .casesdetailPage .fix.rf-arbx.change {
    position: absolute;
    bottom: 115px;
    top: auto; } }

@media (min-width: 768px) {
  .casesdetailPage .fix.rf-arbx {
    top: 100px; } }

@media (min-width: 768px) {
  .casesdetailPage .rf-arbx {
    top: 0px; } }

@media (min-width: 768px) {
  .casesdetailPage .rf-arbx a {
    width: 70px;
    height: 170px;
    line-height: 3;
    transition: .4s ease; }
    .casesdetailPage .rf-arbx a:hover {
      background: #fff;
      opacity: .6;
      color: #deaf6c; } }

@media (min-width: 768px) {
  .casesdetailPage .rf-arbx a img {
    position: relative;
    left: 1px;
    transition: .4s ease;
    display: inline-block;
    margin-bottom: 6px; } }

@media (min-width: 768px) {
  .casesdetailPage .rf-arbx a:last-child {
    background: #f08300;
    color: #fff; }
    .casesdetailPage .rf-arbx a:last-child:hover {
      opacity: 1;
      background: #deaf6c; } }

@media (min-width: 1200px) {
  .casesdetailPage .container {
    max-width: 1056px; } }

.casesdetailPage .sh-indban .bgcover {
  padding-bottom: 24%; }
  @media (max-width: 767px) {
    .casesdetailPage .sh-indban .bgcover {
      padding-bottom: 65.1%; } }

.casesdetailPage .sh-triobx {
  margin-top: 24%; }
  @media (max-width: 767px) {
    .casesdetailPage .sh-triobx {
      margin-top: 35vh; } }

.casesdetailPage #it1 {
  position: relative; }

@media (max-width: 767px) {
  .casesdetailPage .it1-bx {
    padding-top: 20px; } }

.casesdetailPage .it1-bx .d-banner {
  width: 100%;
  margin-top: 115px; }
  @media (max-width: 767px) {
    .casesdetailPage .it1-bx .d-banner {
      margin-top: 85px; } }
  .casesdetailPage .it1-bx .d-banner .bgcover {
    padding-bottom: 480px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .casesdetailPage .it1-bx .d-banner .bgcover {
        padding-bottom: 46.8%; } }
  .casesdetailPage .it1-bx .d-banner .slick-dots {
    width: 190px;
    height: 50px;
    position: absolute;
    margin: 0;
    right: -10px;
    top: -55px;
    pointer-events: none;
    z-index: 10; }
    .casesdetailPage .it1-bx .d-banner .slick-dots li {
      display: flex;
      width: 100%; }
    @media (max-width: 767px) {
      .casesdetailPage .it1-bx .d-banner .slick-dots {
        width: 120px; } }
    .casesdetailPage .it1-bx .d-banner .slick-dots li.slick-active {
      opacity: 1; }
      .casesdetailPage .it1-bx .d-banner .slick-dots li.slick-active i::after {
        animation: dotani 5.3s linear forwards; }
        @media (max-width: 767px) {
          .casesdetailPage .it1-bx .d-banner .slick-dots li.slick-active i::after {
            animation: dotanimo 5.3s linear forwards; } }

@keyframes dotani {
  0% {
    width: 0; }
  100% {
    width: 100px; } }

@keyframes dotanimo {
  0% {
    width: 0; }
  100% {
    width: 50px; } }
    .casesdetailPage .it1-bx .d-banner .slick-dots li {
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 0.6px;
      font-weight: 700;
      color: #787878;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      height: auto !important;
      opacity: 0;
      transition: .4s ease;
      font-family: "Noto Sans TC"; }
      .casesdetailPage .it1-bx .d-banner .slick-dots li i {
        color: #f08300;
        padding-right: 120px;
        margin-right: 20px;
        position: relative; }
        @media (max-width: 767px) {
          .casesdetailPage .it1-bx .d-banner .slick-dots li i {
            padding-right: 50px; } }
        .casesdetailPage .it1-bx .d-banner .slick-dots li i::before {
          position: absolute;
          content: '';
          left: 36px;
          top: 60%;
          transform: translateY(-50%);
          width: 100px;
          height: 2px;
          background-color: #dcdddd; }
          @media (max-width: 767px) {
            .casesdetailPage .it1-bx .d-banner .slick-dots li i::before {
              left: 18px;
              width: 50px; } }
        .casesdetailPage .it1-bx .d-banner .slick-dots li i::after {
          position: absolute;
          content: '';
          left: 36px;
          top: 60%;
          transform: translateY(-50%);
          height: 2px;
          background-color: #f08300; }
          @media (max-width: 767px) {
            .casesdetailPage .it1-bx .d-banner .slick-dots li i::after {
              left: 18px; } }
      @media (max-width: 767px) {
        .casesdetailPage .it1-bx .d-banner .slick-dots li {
          font-size: 0.8125rem; } }

.casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx {
  position: relative;
  margin-bottom: 50px; }
  @media (max-width: 991px) {
    .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx {
      display: block;
      margin-bottom: 25px; } }
  .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-t {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 3.2px;
    font-weight: 400;
    color: #f08300; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-t {
        font-size: 1.625rem; } }
  .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b {
        position: relative;
        right: auto;
        top: auto;
        transform: none; } }
    .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx {
      max-width: 460px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: auto; }
      @media (max-width: 1199px) {
        .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx {
          max-width: 400px; } }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx {
          max-width: 100%;
          display: block;
          margin-top: 23px; } }
      .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a {
        display: block;
        width: 32%;
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
        height: 50px;
        text-align: center;
        border-radius: 10px;
        transition: .4s ease; }
        @media (max-width: 991px) {
          .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a {
            width: 100%;
            height: 48px;
            box-shadow: 0 0 7.3px 0.25px rgba(0, 0, 0, 0.18); }
            .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a:not(:last-child) {
              margin-bottom: 20px; } }
        @media (min-width: 768px) {
          .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a:hover {
            opacity: .5; } }
        .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a span {
          padding-left: 45px;
          position: relative;
          line-height: 3.5;
          margin-left: 0;
          font-size: 1rem; }
          @media (max-width: 991px) {
            .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a span {
              font-size: 0.875rem;
              line-height: 3.8;
              padding-left: 38px; } }
          .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a span img {
            position: absolute;
            left: 0;
            top: 38%;
            transform: translateY(-50%);
            width: 36px; }
            @media (max-width: 991px) {
              .casesdetailPage .it1-bx .caflex-bx .r .ti.ti-bx .ti-b .con-bx a span img {
                width: 25px; } }

.casesdetailPage .it1-bx .inf-bx {
  margin-top: 55px;
  display: flex;
  align-items: center;
  border: 1px solid #f08300;
  border-radius: 10px;
  padding: 40px 32px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 991px) {
    .casesdetailPage .it1-bx .inf-bx {
      display: block;
      border-radius: 5px;
      padding: 20px 16px; } }
  .casesdetailPage .it1-bx .inf-bx::before {
    position: absolute;
    content: '';
    left: 0;
    width: calc(18.5% + 20px);
    height: 100%;
    top: 0;
    background-color: #fef1ea;
    z-index: 0; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .inf-bx::before {
        width: 100%;
        height: 150px; } }
  .casesdetailPage .it1-bx .inf-bx .l {
    position: relative;
    z-index: 5;
    width: 18.5%; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .inf-bx .l {
        width: 100%;
        padding-bottom: 5%; } }
    .casesdetailPage .it1-bx .inf-bx .l .imgti {
      font-size: 1.375rem;
      line-height: 1;
      letter-spacing: 2.2px;
      font-weight: 700;
      color: #000000;
      margin-top: 15px;
      text-align: center; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .l .imgti {
          font-size: 1rem; } }
    .casesdetailPage .it1-bx .inf-bx .l .img-bx {
      max-width: 145px;
      width: 100%; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .l .img-bx {
          max-width: 400px;
          margin: 0 auto;
          text-align: center; } }
      .casesdetailPage .it1-bx .inf-bx .l .img-bx img {
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18); }
        @media (max-width: 991px) {
          .casesdetailPage .it1-bx .inf-bx .l .img-bx img {
            box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
            width: 80px;
            margin: 0 auto; } }
  .casesdetailPage .it1-bx .inf-bx .r {
    padding-left: 4%;
    width: 81.5%; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .inf-bx .r {
        padding-left: 0;
        margin-top: 6%;
        width: 100%; } }
    .casesdetailPage .it1-bx .inf-bx .r .r-bx {
      display: flex; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .r-bx {
          display: block; } }
    .casesdetailPage .it1-bx .inf-bx .r .flist {
      width: 48%; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .flist {
          width: 100%; } }
    .casesdetailPage .it1-bx .inf-bx .r .tlist {
      width: 44%;
      margin-left: 4%;
      padding-left: 4%;
      border-left: 1px solid #f08300;
      position: relative;
      padding-bottom: 93px; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .tlist {
          width: 100%;
          margin-left: 0;
          padding-left: 0;
          width: 100%;
          border: none;
          padding-bottom: 0; } }
      .casesdetailPage .it1-bx .inf-bx .r .tlist .des-ti {
        margin-bottom: 15px; }
      .casesdetailPage .it1-bx .inf-bx .r .tlist .b {
        width: 100%; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .tlist .t {
          margin-top: 17px; } }
    .casesdetailPage .it1-bx .inf-bx .r .ti {
      font-size: 2rem;
      line-height: 1;
      letter-spacing: 3.2px;
      font-weight: 400;
      color: #f08300;
      margin-bottom: 25px; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .ti {
          font-size: 1.625rem;
          margin-bottom: 15px; } }
      .casesdetailPage .it1-bx .inf-bx .r .ti span {
        font-size: 1.125rem;
        line-height: 1;
        letter-spacing: 1px;
        font-weight: 400;
        color: #000000;
        margin-left: 15px; }
        @media (max-width: 991px) {
          .casesdetailPage .it1-bx .inf-bx .r .ti span {
            font-size: 0.875rem;
            margin-left: 10px; } }
    .casesdetailPage .it1-bx .inf-bx .r li {
      padding-bottom: 10px;
      border-bottom: 2px solid #dcdddd;
      display: flex;
      align-items: center; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r li {
          display: block;
          border-bottom: 1px solid #dcdddd; } }
      .casesdetailPage .it1-bx .inf-bx .r li:not(:first-child) {
        margin-top: 25px; }
        @media (max-width: 991px) {
          .casesdetailPage .it1-bx .inf-bx .r li:not(:first-child) {
            margin-top: 17px; } }
      .casesdetailPage .it1-bx .inf-bx .r li .des-ti {
        margin-right: 23px; }
  .casesdetailPage .it1-bx .inf-bx .r {
    position: relative; }
    .casesdetailPage .it1-bx .inf-bx .r .b {
      position: absolute;
      bottom: 0; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .b {
          position: relative;
          bottom: auto;
          margin-top: 17px; } }
      .casesdetailPage .it1-bx .inf-bx .r .b .des {
        padding-bottom: 14px;
        border-bottom: 2px solid #dcdddd; }
        @media (max-width: 991px) {
          .casesdetailPage .it1-bx .inf-bx .r .b .des {
            border-bottom: 1px solid #dcdddd; } }
    @media (min-width: 992px) {
      .casesdetailPage .it1-bx .inf-bx .r .des-ti {
        min-width: 99px; } }
    .casesdetailPage .it1-bx .inf-bx .r .adr {
      display: block;
      font-size: 1rem;
      line-height: 1.2;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000000;
      position: relative;
      padding-left: 26px;
      font-family: "Noto Sans TC";
      padding-bottom: 14px;
      border-bottom: 2px solid #dcdddd; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .adr {
          border-bottom: 1px solid #dcdddd; } }
      .casesdetailPage .it1-bx .inf-bx .r .adr img {
        width: 15px;
        position: absolute;
        left: 0; }
    .casesdetailPage .it1-bx .inf-bx .r .tel {
      display: block;
      font-size: 1rem;
      line-height: 1.2;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000000;
      position: relative;
      padding-left: 26px;
      font-family: "Noto Sans TC";
      padding-bottom: 14px;
      border-bottom: 2px solid #dcdddd;
      margin-top: 15px; }
      @media (max-width: 991px) {
        .casesdetailPage .it1-bx .inf-bx .r .tel {
          border-bottom: 1px solid #dcdddd; } }
      .casesdetailPage .it1-bx .inf-bx .r .tel img {
        width: 20px;
        position: absolute;
        left: 0; }
  .casesdetailPage .it1-bx .inf-bx .des-ti {
    font-size: 1.25rem;
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000;
    padding-left: 15px;
    position: relative;
    font-family: "Noto Sans TC"; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .inf-bx .des-ti {
        font-size: 1rem; } }
    .casesdetailPage .it1-bx .inf-bx .des-ti::before {
      position: absolute;
      content: '';
      left: 0;
      top: 3px;
      width: 5px;
      height: 80%;
      background-color: #deaf6c; }
  .casesdetailPage .it1-bx .inf-bx .des {
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000;
    font-family: "Noto Sans TC"; }
    @media (max-width: 991px) {
      .casesdetailPage .it1-bx .inf-bx .des {
        font-size: 0.875rem;
        margin-top: 14px; } }

.casesdetailPage .full-bg {
  width: 100%;
  margin-top: 80px; }
  @media (max-width: 991px) {
    .casesdetailPage .full-bg {
      margin-top: 40px; } }
  .casesdetailPage .full-bg .bgcover {
    padding-bottom: 35.4%;
    background-size: cover !important; }
    @media (max-width: 991px) {
      .casesdetailPage .full-bg .bgcover {
        padding-bottom: 35.4%; } }

.casesdetailPage .ti-bx {
  display: flex;
  align-items: flex-end; }
  .casesdetailPage .ti-bx .en {
    font-size: 2rem;
    line-height: 0.8;
    letter-spacing: 3.2px;
    font-weight: 400;
    color: #f08300;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .casesdetailPage .ti-bx .en {
        font-size: 1.625rem; } }
  .casesdetailPage .ti-bx .t {
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000; }
    @media (max-width: 767px) {
      .casesdetailPage .ti-bx .t {
        font-size: 0.875rem; } }

.casesdetailPage .map-bx {
  margin-top: 85px; }
  @media (max-width: 991px) {
    .casesdetailPage .map-bx {
      margin-top: 45px; } }
  .casesdetailPage .map-bx iframe {
    margin-top: 30px;
    margin-bottom: 85px;
    width: 100%;
    height: 480px; }
    @media (max-width: 767px) {
      .casesdetailPage .map-bx iframe {
        margin-top: 15px;
        margin-bottom: 45px;
        height: 240px;
        height: 150px; } }

.casesdetailPage .morelist {
  margin-top: 30px;
  padding-bottom: 55px;
  cursor: url("../images/cased/mouse.png"), auto; }
  @media (max-width: 767px) {
    .casesdetailPage .morelist {
      padding-bottom: 25px; } }
  .casesdetailPage .morelist .slick-list {
    padding-left: 440px; }
  .casesdetailPage .morelist li {
    width: 460px;
    margin: 0 50px;
    overflow: hidden; }
    @media (max-width: 767px) {
      .casesdetailPage .morelist li {
        width: 80vw;
        margin: 0 8px; } }
    .casesdetailPage .morelist li a {
      display: block; }
      @media (min-width: 768px) {
        .casesdetailPage .morelist li a:hover .img-bx::before {
          opacity: 1; }
        .casesdetailPage .morelist li a:hover .img-bx .hov-more {
          opacity: 1; }
        .casesdetailPage .morelist li a:hover .des::before {
          width: 100%; } }
      .casesdetailPage .morelist li a .img-bx {
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
        border-radius: 10px;
        position: relative;
        overflow: hidden; }
        @media (max-width: 767px) {
          .casesdetailPage .morelist li a .img-bx {
            border-radius: 5px; } }
        .casesdetailPage .morelist li a .img-bx::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          transition: .4s ease;
          opacity: 0; }
        .casesdetailPage .morelist li a .img-bx .hov-more {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 1px;
          font-weight: 400;
          color: #fff;
          transition: .4s ease;
          opacity: 0;
          display: flex;
          align-items: center;
          font-family: "Noto Sans TC"; }
          .casesdetailPage .morelist li a .img-bx .hov-more img {
            margin-right: 10px; }
      .casesdetailPage .morelist li a .da {
        font-size: 1.25rem;
        line-height: 1.2;
        letter-spacing: 1px;
        font-weight: 400;
        color: #f08300;
        margin-bottom: 10px;
        margin-top: 20px; }
        @media (max-width: 767px) {
          .casesdetailPage .morelist li a .da {
            font-size: 1rem;
            margin-top: 16px;
            margin-bottom: 5px; } }
      .casesdetailPage .morelist li a .des {
        font-size: 1.375rem;
        line-height: 1.64;
        letter-spacing: 2.2px;
        font-weight: 700;
        color: #000000;
        position: relative;
        height: 3.28em;
        line-height: 1.64;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 第幾行要顯示點點點 */
        -webkit-box-orient: vertical;
        height: auto;
        max-height: 3.28em;
        font-family: "Noto Sans TC"; }
        @media (max-width: 767px) {
          .casesdetailPage .morelist li a .des {
            font-size: 1rem; } }
        .casesdetailPage .morelist li a .des::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 0;
          height: 3px;
          background: #f08300;
          transition: .6s ease; }

.casesdetailPage .morebor {
  margin-bottom: 40px;
  width: 100%;
  height: 2px;
  background-color: #dcdddd; }
  @media (max-width: 767px) {
    .casesdetailPage .morebor {
      height: 1px; } }

.casesdetailPage .back-btn {
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.8px;
  font-weight: 400;
  color: #000;
  padding: 12px 17px;
  border: 1px solid #f08300;
  border-radius: 10px;
  background-color: #ffffff;
  font-family: "Noto Sans TC";
  transition: .4s ease;
  display: block;
  width: 125px;
  margin: 0 auto;
  margin-bottom: 140px;
  text-align: center; }
  @media (min-width: 768px) {
    .casesdetailPage .back-btn:hover {
      background-color: #f08300;
      color: #fff; } }
  @media (max-width: 767px) {
    .casesdetailPage .back-btn {
      font-size: 0.875rem;
      padding: 10px 13px;
      border-radius: 5px;
      margin-bottom: 100px;
      width: 102px; } }

.classicdetailPage .sh-indban::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #10183f; }

.classicdetailPage .sh-indban::after {
  position: absolute;
  content: '';
  top: calc(50% - 15px);
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../images/classicd/mb_text.png) no-repeat center/contain;
  width: 88vw;
  height: 120px; }
  @media (min-width: 768px) {
    .classicdetailPage .sh-indban::after {
      width: 30.2vw;
      top: calc(50% - 45px); } }
  @media (max-width: 767px) and (min-width: 450px) {
    .classicdetailPage .sh-indban::after {
      top: calc(30% - 15px); } }

@media (min-width: 1200px) {
  .contactPage .container {
    max-width: 1056px; } }

.contactPage .overbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s linear;
  z-index: 1012;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none; }

.contactPage .modal-content {
  max-width: 767px;
  max-height: 600px;
  width: 90%;
  height: 71vh;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -7px;
  z-index: 999;
  display: none; }
  @media (max-width: 640px) {
    .contactPage .modal-content {
      padding: 50px 2.5%;
      margin-left: 0px; } }
  .contactPage .modal-content .modalBox {
    width: 100%;
    height: 100%;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    padding: 25px 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 0.75rem;
    color: #666; }
    @media (max-width: 640px) {
      .contactPage .modal-content .modalBox {
        padding: 20px 10px 20px 15px; } }
  .contactPage .modal-content #telbox {
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: black; }
    .contactPage .modal-content #telbox a[href^="tel:"] {
      max-width: 450px;
      width: 80%;
      color: white;
      background-color: #1e4c5e;
      display: block;
      margin: 35px auto 0;
      padding: 15px 0; }
    .contactPage .modal-content #telbox > div {
      flex: 0 0 100%; }
    .contactPage .modal-content #telbox .txt {
      font-size: 1.25rem;
      margin: 10px 0 0; }
  .contactPage .modal-content.telbox {
    height: 400px; }
    .contactPage .modal-content.telbox #telbox {
      display: flex; }
  .contactPage .modal-content.agreebox .text-justify {
    display: block; }
  .contactPage .modal-content .text-justify {
    display: none;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    line-height: 1.5;
    text-align: initial; }
    @media (max-width: 640px) {
      .contactPage .modal-content .text-justify {
        padding-right: 5px; } }
  .contactPage .modal-content .close {
    cursor: pointer;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    z-index: 99993;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px; }
    .contactPage .modal-content .close:before, .contactPage .modal-content .close:after {
      position: absolute;
      left: 18px;
      top: 4px;
      content: ' ';
      height: 30px;
      width: 2px;
      background-color: #909399; }
    .contactPage .modal-content .close:before {
      transform: rotate(45deg); }
    .contactPage .modal-content .close:after {
      transform: rotate(-45deg); }
    @media (max-width: 640px) {
      .contactPage .modal-content .close {
        right: 2.5%; } }
  .contactPage .modal-content h4 {
    text-align: center;
    font-size: 2rem;
    color: #303133;
    margin-bottom: 36px; }
  .contactPage .modal-content h6 {
    font-size: 1.5rem;
    color: #000000;
    font-weight: 700;
    margin-bottom: 12px; }

@media (max-width: 767px) {
  .contactPage .item1 .it1-bx {
    padding-top: 0px; } }

.contactPage .item1 .form-bx {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .contactPage .item1 .form-bx {
      margin-top: 40px; } }
  .contactPage .item1 .form-bx .flex-bx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .flex-bx {
        display: block; } }
    .contactPage .item1 .form-bx .flex-bx .form-group {
      width: 48%;
      margin-bottom: 24px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .flex-bx .form-group {
          width: 100%;
          margin-bottom: 8px; } }
    .contactPage .item1 .form-bx .flex-bx .form-radiobx {
      width: 48%; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .flex-bx .form-radiobx {
          width: 100%;
          margin-top: 30px; } }
  .contactPage .item1 .form-bx input {
    background-color: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #f08300;
    height: 34px; }
  .contactPage .item1 .form-bx label, .contactPage .item1 .form-bx .ti {
    font-size: 1.25rem;
    line-height: 1.75;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #000000;
    position: relative;
    font-family: "Noto Sans TC"; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx label, .contactPage .item1 .form-bx .ti {
        font-size: 1rem; } }
    .contactPage .item1 .form-bx label span, .contactPage .item1 .form-bx .ti span {
      color: #f08300;
      font-family: "Noto Sans TC"; }
  .contactPage .item1 .form-bx .help-block {
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.7px;
    font-weight: 500;
    color: #f08300;
    height: 15px;
    margin-top: 8px; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .help-block {
        font-size: 0.875rem; } }
  .contactPage .item1 .form-bx .form-radiobx {
    margin-top: 5px; }
    @media (max-width: 991px) {
      .contactPage .item1 .form-bx .form-radiobx {
        flex-wrap: wrap;
        margin-top: 0px; } }
    .contactPage .item1 .form-bx .form-radiobx .radio-bx {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .form-radiobx .radio-bx {
          margin-top: 5px; } }
    .contactPage .item1 .form-bx .form-radiobx .form-radio {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 991px) {
        .contactPage .item1 .form-bx .form-radiobx .form-radio {
          margin-bottom: 0px; } }
      .contactPage .item1 .form-bx .form-radiobx .form-radio input {
        display: none; }
      .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=text] {
        display: block;
        width: 167px;
        margin-left: -10px;
        position: relative;
        top: -3px;
        font-size: 1rem;
        line-height: 1.75;
        letter-spacing: 0.8px;
        font-weight: 500;
        color: #000000; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=text] {
            top: 0;
            height: 28px;
            width: 110px; } }
      .contactPage .item1 .form-bx .form-radiobx .form-radio label {
        padding-left: 27px;
        margin-right: 23px;
        margin-bottom: 5px;
        cursor: pointer; }
        @media (max-width: 768px) {
          .contactPage .item1 .form-bx .form-radiobx .form-radio label {
            padding-left: 22px;
            margin-bottom: 3px; } }
      .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=radio] + label:before {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 1px solid #f58905;
        border-radius: 100px;
        position: absolute;
        top: 10px;
        left: 0;
        content: ""; }
        @media (max-width: 768px) {
          .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=radio] + label:before {
            top: 7px;
            width: 15px;
            height: 15px; } }
      .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=radio]:checked + label:after {
        width: 8px;
        height: 8px;
        background-color: #f08300;
        border-radius: 100px;
        position: absolute;
        content: "";
        top: 15px;
        left: 5px; }
        @media (max-width: 768px) {
          .contactPage .item1 .form-bx .form-radiobx .form-radio input[type=radio]:checked + label:after {
            top: 11px;
            width: 7px;
            height: 7px;
            left: 4px; } }
      .contactPage .item1 .form-bx .form-radiobx .form-radio .form-radio {
        position: relative;
        margin-right: 25px; }
        @media (max-width: 992px) {
          .contactPage .item1 .form-bx .form-radiobx .form-radio .form-radio {
            width: 50%;
            margin-right: 0px; } }
        .contactPage .item1 .form-bx .form-radiobx .form-radio .form-radio:first-child {
          margin-right: 30px; }
          @media (max-width: 992px) {
            .contactPage .item1 .form-bx .form-radiobx .form-radio .form-radio:first-child {
              margin-right: 0px; } }
  .contactPage .item1 .form-bx .sel-ti {
    font-size: 1.25rem;
    line-height: 1.75;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #000000;
    position: relative;
    font-family: "Noto Sans TC";
    margin-bottom: 30px; }
    @media (max-width: 768px) {
      .contactPage .item1 .form-bx .sel-ti {
        font-size: 1rem;
        margin-bottom: 12px;
        margin-top: 25px; } }
  .contactPage .item1 .form-bx .arbx:not(:last-child) {
    margin-bottom: 50px; }
    @media (max-width: 768px) {
      .contactPage .item1 .form-bx .arbx:not(:last-child) {
        margin-bottom: 25px; } }
  .contactPage .item1 .form-bx .arbx .active.t-ti {
    border-bottom: 1px solid #f08300; }
    .contactPage .item1 .form-bx .arbx .active.t-ti::before {
      transform: none; }
  .contactPage .item1 .form-bx .arbx .t-ti {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 3.2px;
    font-weight: 400;
    color: #f08300;
    font-family: "Bilbo";
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdddd;
    cursor: pointer;
    position: relative;
    transition: .4s ease; }
    @media (max-width: 768px) {
      .contactPage .item1 .form-bx .arbx .t-ti {
        font-size: 1.625rem; } }
    .contactPage .item1 .form-bx .arbx .t-ti::before {
      position: absolute;
      content: '';
      top: 11px;
      right: 0;
      width: 22px;
      height: 11px;
      background: url(../images/contact/sel.png) no-repeat center center/contain;
      transform: rotate(180deg);
      transition: .6s ease; }
      @media (max-width: 768px) {
        .contactPage .item1 .form-bx .arbx .t-ti::before {
          width: 19px;
          height: 8px; } }
    .contactPage .item1 .form-bx .arbx .t-ti span {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 1px;
      font-weight: 400;
      color: #000; }
      @media (max-width: 768px) {
        .contactPage .item1 .form-bx .arbx .t-ti span {
          font-size: 0.875rem; } }
  .contactPage .item1 .form-bx .arbx .b-bx {
    padding: 40px 46px;
    border-bottom: 1px solid #f08300;
    padding-bottom: 0;
    background-color: #fff9f5;
    display: none; }
    @media (max-width: 768px) {
      .contactPage .item1 .form-bx .arbx .b-bx {
        padding: 20px 23px; } }
  .contactPage .item1 .form-bx .form-check-box {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .contactPage .item1 .form-bx .form-check-box {
        justify-content: space-between; } }
    .contactPage .item1 .form-bx .form-check-box .form-chitem {
      position: relative;
      margin-bottom: 40px;
      width: 21%; }
      @media (max-width: 768px) {
        .contactPage .item1 .form-bx .form-check-box .form-chitem {
          margin-top: 5px;
          width: 47.5%;
          margin-bottom: 30px; } }
      .contactPage .item1 .form-bx .form-check-box .form-chitem .img-bx {
        max-width: 195px;
        width: 100%;
        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
        margin-bottom: 5px; }
      .contactPage .item1 .form-bx .form-check-box .form-chitem label {
        display: block;
        position: static; }
      .contactPage .item1 .form-bx .form-check-box .form-chitem:not(:nth-child(4n)) {
        margin-right: 4%; }
        @media (max-width: 768px) {
          .contactPage .item1 .form-bx .form-check-box .form-chitem:not(:nth-child(4n)) {
            margin-right: 0; } }
    .contactPage .item1 .form-bx .form-check-box input {
      display: none; }
    .contactPage .item1 .form-bx .form-check-box label {
      cursor: pointer;
      color: #000000;
      margin-right: 0px; }
      .contactPage .item1 .form-bx .form-check-box label p {
        color: #000000;
        font-size: 1.25rem;
        letter-spacing: 2.7px;
        margin-left: 30px;
        position: relative; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .form-check-box label p {
            font-size: 1rem; } }
    .contactPage .item1 .form-bx .form-check-box .form-chitem input[type=checkbox] + label p:before {
      width: 20px;
      height: 20px;
      border: 1px solid #f08300;
      position: absolute;
      content: "";
      top: 8px;
      left: -26px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .form-check-box .form-chitem input[type=checkbox] + label p:before {
          width: 18px;
          height: 18px;
          top: 6px; } }
    .contactPage .item1 .form-bx .form-check-box .form-chitem input[type=checkbox]:checked + label p:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 12px;
      background: url(../images/contact/checkbox.png) no-repeat;
      top: 11px;
      left: -20px; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .form-check-box .form-chitem input[type=checkbox]:checked + label p:after {
          width: 9px;
          height: 12px;
          top: 8px; } }
  @media (max-width: 767px) {
    .contactPage .item1 .form-bx .form-textbx {
      margin-top: 5px; } }
  .contactPage .item1 .form-bx .form-textbx .textarea-wrapper {
    height: 276px;
    overflow: hidden;
    border: none;
    padding: 0px 0px 10px;
    margin-top: 10px;
    background-color: #eeeeee; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .form-textbx .textarea-wrapper {
        height: 140px; } }
    .contactPage .item1 .form-bx .form-textbx .textarea-wrapper textarea {
      min-height: 262px;
      height: 0;
      overflow: hidden;
      border-bottom: none;
      padding-top: 14px;
      padding-left: 11px;
      text-rendering: auto;
      -webkit-writing-mode: horizontal-tb !important;
      background-color: #eeeeee;
      resize: none;
      border: none;
      font-family: "Noto Sans TC";
      cursor: text; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .form-textbx .textarea-wrapper textarea {
          min-height: 126px; } }
      .contactPage .item1 .form-bx .form-textbx .textarea-wrapper textarea:focus {
        border: none; }
  .contactPage .item1 .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #f08300;
    width: 2px; }
  .contactPage .item1 .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #dcdddd;
    width: 2px; }
  .contactPage .item1 .form-bx .form-textbx .mCSB_scrollTools {
    right: -5px; }
  .contactPage .item1 .form-bx .form-textbx .mCSB_inside > .mCSB_container {
    margin-right: 13px; }
  .contactPage .item1 .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
    top: 15px;
    left: -12px; }
  .contactPage .item1 .form-bx .agree {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 55px; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .agree {
        margin-top: 15px;
        margin-bottom: 27px; } }
  .contactPage .item1 .form-bx .agr-des {
    text-align: center;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0px;
    font-weight: 400;
    color: #00B1FF;
    margin-top: 10px;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .agr-des {
        font-size: 0.8125rem;
        margin-top: 20px;
        margin-bottom: 20px; } }
  .contactPage .item1 .form-bx .check_radi {
    padding-left: 30px;
    height: 32px;
    text-align: left;
    width: auto;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: inline-block; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .check_radi {
        padding-left: 30px; } }
    .contactPage .item1 .form-bx .check_radi input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
      z-index: 5;
      width: 24px;
      height: 24px; }
    .contactPage .item1 .form-bx .check_radi .checkmark {
      position: absolute;
      top: 10px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 0;
      background-color: #ffffff;
      border: solid 1px #f08300; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .check_radi .checkmark {
          top: 4px; } }
    .contactPage .item1 .form-bx .check_radi .checkmark:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 5px;
      display: none;
      width: 10px;
      height: 13px;
      background: url("../images/contact/checkbox.png") no-repeat center/contain; }
    .contactPage .item1 .form-bx .check_radi input:checked ~ .checkmark:after {
      display: block; }
    .contactPage .item1 .form-bx .check_radi a[data-popup] {
      color: #000000;
      position: relative; }
      .contactPage .item1 .form-bx .check_radi a[data-popup]:hover {
        text-decoration: none; }
  .contactPage .item1 .form-bx .send-btn {
    width: 240px;
    height: 57px;
    border-radius: 10px;
    border: solid 1px #f08300;
    transition: .4s ease;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    line-height: 4;
    letter-spacing: 0.8px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 140px; }
    @media (max-width: 767px) {
      .contactPage .item1 .form-bx .send-btn {
        margin-bottom: 110px;
        width: 210px;
        height: 48px;
        border-radius: 5px;
        font-size: 0.875rem;
        line-height: 3.8; } }
    @media (min-width: 768px) {
      .contactPage .item1 .form-bx .send-btn:hover {
        background-color: #f08300;
        color: #fff; }
        .contactPage .item1 .form-bx .send-btn:hover span:before {
          border-left: 9px solid #fff; } }
    .contactPage .item1 .form-bx .send-btn span {
      padding-right: 27px;
      position: relative; }
      @media (max-width: 767px) {
        .contactPage .item1 .form-bx .send-btn span {
          padding-right: 20px; } }
      .contactPage .item1 .form-bx .send-btn span::before {
        position: absolute;
        right: 0;
        top: 2px;
        content: '';
        border: 6px dashed transparent;
        border-right: none;
        border-left: 9px solid #f08300;
        transition: .4s ease; }
        @media (max-width: 767px) {
          .contactPage .item1 .form-bx .send-btn span::before {
            top: 1px; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1232px; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 30px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 30px; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 0.875rem;
      color: #000000; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px;
          margin: 0 20px 0 0; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 10px;
          padding: 7px 0; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: black;
      color: black; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10;
    background-color: #000000; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    right: -18px; }
  .flex-direction-nav a.flex-prev {
    left: -18px; }

.open_flexslider .flex-direction-nav {
  display: block; }

.rfcon-bx {
  position: fixed;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  z-index: 34;
  cursor: context-menu;
  transition: .4s ease; }
  @media (min-width: 768px) {
    .rfcon-bx:hover {
      right: 0; }
      .rfcon-bx:hover .l .ti:before {
        border-right: none;
        border-left: 13px solid #f08300;
        border-top: 9px dashed transparent;
        border-bottom: 9px dashed transparent; } }
  .rfcon-bx .l {
    width: 50px;
    height: 201px;
    background-color: #fff;
    border-radius: 10px 0 0 10px;
    padding-top: 17px;
    box-shadow: 0 0 23.8px 1.3px rgba(0, 0, 0, 0.1);
    z-index: 5; }
    .rfcon-bx .l .img-bx {
      text-align: center;
      margin-bottom: 10px; }
    .rfcon-bx .l .ti {
      font-size: 1.25rem;
      line-height: 1.2;
      letter-spacing: 2px;
      font-weight: 400;
      color: #000000;
      width: 20px;
      margin: 0 auto;
      padding-bottom: 30px;
      position: relative; }
      .rfcon-bx .l .ti::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 4px;
        border-right: 13px solid #000;
        border-top: 9px dashed transparent;
        border-bottom: 9px dashed transparent; }
  .rfcon-bx .r {
    height: 201px;
    width: 70px;
    background-color: #deaf6c;
    box-shadow: 0 0 23.8px 1.3px rgba(0, 0, 0, 0.1);
    position: relative; }
    .rfcon-bx .r .link-bx {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%); }
      .rfcon-bx .r .link-bx a {
        display: block;
        text-align: center;
        opacity: .6;
        transition: .4s ease; }
        @media (min-width: 768px) {
          .rfcon-bx .r .link-bx a:hover {
            opacity: 1; } }
        .rfcon-bx .r .link-bx a img {
          margin: 0 auto; }
  @media (max-width: 767px) {
    .rfcon-bx {
      bottom: 0;
      top: auto;
      right: auto;
      left: 0;
      transform: none;
      width: 100%; }
      .rfcon-bx .l {
        width: auto;
        height: 50px;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        align-items: center;
        width: 40%;
        padding-left: 25px;
        padding-top: 0; }
        .rfcon-bx .l .ti {
          width: auto;
          font-size: 1rem;
          padding-bottom: 0;
          margin: 0;
          margin-left: 10px;
          margin-top: 4px; }
          .rfcon-bx .l .ti::before {
            display: none; }
        .rfcon-bx .l .img-bx {
          width: 18px;
          margin-bottom: 0; }
      .rfcon-bx .r {
        width: auto;
        height: 50px;
        background-color: #fff;
        box-shadow: none;
        width: 60%;
        padding-right: 25px; }
        .rfcon-bx .r .link-bx {
          margin-top: 0;
          display: flex;
          align-items: center;
          height: 100%; }
          .rfcon-bx .r .link-bx a {
            opacity: 1;
            display: flex;
            align-items: center; }
            .rfcon-bx .r .link-bx a img {
              width: 30px; }
            .rfcon-bx .r .link-bx a:first-child {
              margin-bottom: 0;
              margin-right: 9%; }
            .rfcon-bx .r .link-bx a span {
              font-size: 1rem;
              line-height: 1;
              letter-spacing: 1px;
              font-weight: 400;
              color: #000000;
              font-family: "Noto Sans TC";
              margin-left: 5px; } }
  @media (max-width: 340px) {
    .rfcon-bx .r {
      padding-right: 10px; }
      .rfcon-bx .r .link-bx a img {
        width: 19px; }
    .rfcon-bx .l {
      padding-left: 10px; } }

.sh-tibx {
  text-align: center; }
  .sh-tibx .en-ti {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 3.2px;
    font-weight: 400;
    color: #f08300;
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      .sh-tibx .en-ti {
        font-size: 1.625rem; } }
  .sh-tibx .ti {
    font-size: 1.875rem;
    line-height: 1;
    letter-spacing: 3px;
    font-weight: 400;
    color: #000000;
    font-family: "Noto Sans TC"; }
    @media (max-width: 767px) {
      .sh-tibx .ti {
        font-size: 1.3125rem; } }

.sh-tagbx {
  display: flex;
  flex-wrap: wrap; }
  .sh-tagbx a:not(:last-child) {
    margin-right: 15px; }

.active.sh-tag {
  background-color: #f08300;
  color: #fff; }

.sh-tag {
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.8px;
  font-weight: 400;
  color: #000;
  padding: 12px 17px;
  border: 1px solid #f08300;
  border-radius: 10px;
  background-color: #ffffff;
  display: block;
  font-family: "Noto Sans TC";
  transition: .4s ease;
  margin-bottom: 14px; }
  @media (min-width: 768px) {
    .sh-tag:hover {
      background-color: #f08300;
      color: #fff; } }
  @media (max-width: 767px) {
    .sh-tag {
      font-size: 0.875rem;
      padding: 10px 13px;
      border-radius: 5px; } }

.sh-indban {
  position: fixed;
  top: 100px;
  width: 100%; }
  @media (max-width: 767px) {
    .sh-indban {
      top: 70px; } }
  .sh-indban h1 {
    display: none; }
  .sh-indban .bgcover {
    background-size: cover !important;
    padding-bottom: 340px; }
    @media (max-width: 767px) {
      .sh-indban .bgcover {
        padding-bottom: 52%; } }

.sh-indban2 {
  position: fixed;
  top: 100px;
  width: 100%; }
  @media (max-width: 767px) {
    .sh-indban2 {
      top: 70px; } }
  .sh-indban2 h1 {
    display: none; }
  .sh-indban2 .bgcover {
    background-size: cover !important;
    padding-bottom: 1080px; }
    @media (max-width: 767px) {
      .sh-indban2 .bgcover {
        padding-bottom: 178%; } }

.sh-triobx {
  margin-top: 36vh;
  background: #fff; }
  @media (max-width: 767px) {
    .sh-triobx {
      margin-top: 51%; } }

.sh-tribx {
  background: #fff;
  position: relative; }
  .sh-tribx::before {
    position: absolute;
    content: '';
    top: -94px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 95px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #fff; }
    @media (max-width: 767px) {
      .sh-tribx::before {
        height: 43px;
        top: -42px; } }

.sh-areabx .ti-bx, .sh-area3bx .ti-bx {
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
  border-bottom: 1px solid #f08300;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .sh-areabx .ti-bx, .sh-area3bx .ti-bx {
      margin-bottom: 20px; } }
  .sh-areabx .ti-bx .e, .sh-area3bx .ti-bx .e {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 3.2px;
    font-weight: 400;
    color: #f08300;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .sh-areabx .ti-bx .e, .sh-area3bx .ti-bx .e {
        font-size: 1.5625rem;
        margin-right: 10px; } }
  .sh-areabx .ti-bx .t, .sh-area3bx .ti-bx .t {
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: 400;
    color: #000000; }
    @media (max-width: 767px) {
      .sh-areabx .ti-bx .t, .sh-area3bx .ti-bx .t {
        font-size: 0.875rem; } }

.sh-areabx ul, .sh-area3bx ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .sh-areabx ul li, .sh-area3bx ul li {
    width: 48.5%;
    margin-bottom: 33px;
    border-radius: 10px;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18); }
    @media (max-width: 767px) {
      .sh-areabx ul li, .sh-area3bx ul li {
        border-radius: 5px;
        margin-bottom: 16px;
        width: 100%;
        box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18); } }
    .sh-areabx ul li a, .sh-area3bx ul li a {
      display: block;
      position: relative;
      border-radius: 10px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .sh-areabx ul li a, .sh-area3bx ul li a {
          border-radius: 5px; } }
      .sh-areabx ul li a .img-bx, .sh-area3bx ul li a .img-bx {
        position: relative; }
        .sh-areabx ul li a .img-bx::before, .sh-area3bx ul li a .img-bx::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          transition: .4s ease;
          opacity: 0; }
        .sh-areabx ul li a .img-bx img, .sh-area3bx ul li a .img-bx img {
          width: 100%; }
      .sh-areabx ul li a .des-bx, .sh-area3bx ul li a .des-bx {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        text-align: center;
        transition: .4s ease;
        opacity: 0; }
        .sh-areabx ul li a .des-bx .ti, .sh-area3bx ul li a .des-bx .ti {
          font-size: 1.3125rem;
          line-height: 1.1;
          letter-spacing: 2px;
          font-weight: 700;
          color: #ffffff;
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid #fff;
          font-family: "Noto Sans TC"; }
        .sh-areabx ul li a .des-bx .des, .sh-area3bx ul li a .des-bx .des {
          font-size: 0.9375rem;
          line-height: 1.8;
          letter-spacing: 1.5px;
          font-weight: 400;
          color: #fff;
          font-family: "Noto Sans TC"; }
      @media (min-width: 768px) {
        .sh-areabx ul li a:hover .img-bx::before, .sh-area3bx ul li a:hover .img-bx::before {
          opacity: 1; }
        .sh-areabx ul li a:hover .des-bx, .sh-area3bx ul li a:hover .des-bx {
          opacity: 1; } }

@media (min-width: 992px) {
  .sh-area3bx ul {
    justify-content: start; } }

.sh-area3bx ul li {
  width: 31%; }
  @media (min-width: 992px) {
    .sh-area3bx ul li:not(:nth-child(3n)) {
      margin-right: 3.5%; } }
  @media (max-width: 991px) {
    .sh-area3bx ul li {
      width: 100%; } }

.hiddendiv {
  display: none; }

.overbg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s linear;
  z-index: 1012;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none; }

.modal-content {
  max-width: 767px;
  max-height: 600px;
  width: 90%;
  height: 95%;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -7px;
  z-index: 999;
  display: none; }
  @media (max-width: 640px) {
    .modal-content {
      padding: 50px 2.5%;
      margin-left: 0px; } }
  .modal-content .modalBox {
    width: 100%;
    height: 100%;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    padding: 25px 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 0.75rem;
    color: #666; }
    @media (max-width: 640px) {
      .modal-content .modalBox {
        padding: 20px 10px 20px 15px; } }
  .modal-content #telbox {
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: black; }
    .modal-content #telbox a[href^="tel:"] {
      max-width: 450px;
      width: 80%;
      color: #000;
      background-color: none;
      display: block;
      margin: 35px auto 0;
      padding: 14px 0;
      border: 1px solid #f08300;
      padding-top: 18px;
      border-radius: 10px; }
      @media (max-width: 767px) {
        .modal-content #telbox a[href^="tel:"] {
          margin-top: 20px;
          border-radius: 5px; } }
      @media (min-width: 768px) {
        .modal-content #telbox a[href^="tel:"]:hover {
          background-color: #f08300; }
          .modal-content #telbox a[href^="tel:"]:hover span {
            color: #fff; } }
      .modal-content #telbox a[href^="tel:"] span {
        position: relative; }
    .modal-content #telbox img {
      width: 28px; }
    .modal-content #telbox > div {
      flex: 0 0 100%; }
    .modal-content #telbox .txt {
      font-size: 1.25rem;
      margin: 20px 0 0;
      font-family: "Noto Sans TC"; }
      @media (max-width: 767px) {
        .modal-content #telbox .txt {
          margin: 15px 0 0; } }
    .modal-content #telbox .titx {
      font-size: 1.375rem; }
      @media (max-width: 767px) {
        .modal-content #telbox .titx {
          font-size: 1.125rem; } }
  .modal-content.telbox {
    height: 400px; }
    .modal-content.telbox #telbox {
      display: flex; }
  .modal-content.fbbox {
    height: 400px; }
    .modal-content.fbbox #fbbox {
      display: flex; }
  .modal-content.agreebox .text-justify {
    display: block; }
  .modal-content .text-justify {
    display: none;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    line-height: 1.5;
    text-align: initial; }
    @media (max-width: 640px) {
      .modal-content .text-justify {
        padding-right: 5px; } }
  .modal-content .close {
    cursor: pointer;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    z-index: 99993;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: 0px;
    right: 0px; }
    .modal-content .close:before, .modal-content .close:after {
      position: absolute;
      left: 18px;
      top: 4px;
      content: ' ';
      height: 30px;
      width: 2px;
      background-color: #909399; }
    .modal-content .close:before {
      transform: rotate(45deg); }
    .modal-content .close:after {
      transform: rotate(-45deg); }
    @media (max-width: 640px) {
      .modal-content .close {
        right: 2.5%; } }
  .modal-content h4 {
    text-align: center;
    font-size: 2rem;
    color: #303133;
    margin-bottom: 36px; }
  .modal-content h6 {
    font-size: 1.5rem;
    color: #000000;
    font-weight: 700;
    margin-bottom: 12px; }
