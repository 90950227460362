/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 32;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 100px;
    padding: 0 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    // background: url(../images/bavv.jpg) top left no-repeat;
    background-color: #fff;
    @media (max-width:1366px) {
        padding: 0 50px;
    }
    @media (max-width:991px) {
        padding: 0 20px;
    }
    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 9px;
        background: rgba(255,255,255,0);
        bottom: 0;
        left: 0;
        z-index: 53;
        box-shadow: 0px 10px 10.8px 1.3px rgba(0,0,0,.1);
        @media (max-width:991px) {
            box-shadow:0 10px 8.8px 0.3px rgba(0,0,0,.1);
        }
    }
}



.logo {
    width: 197px;
    height: 42px;
    background: url(../images/logo.png) top left no-repeat;
    background-size: contain;
    text-indent: -10000px;
    a {
        @include aLink();
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
  
    ul {
        display: flex;
        li{
            position: relative;
            text-align: center;
            // width: 80px;
        }
        li:not(:last-child){
            margin-right: 54px;
            @media (max-width:991px) {
                margin-right: 20px;
            }
            &::before{
                position: absolute;
                content: '';
                right: -26px;
                top: 3px;
                width: 1px;
                height: 26px;
                background-color: #dcdddd;
                @media (max-width:991px) {
                    right: -10px;
                }
            }
        }
        li.active{
            a{
                color: $hover;
                &::after{
                    opacity: 1;
                }
            }
        }
    }
    a{
        width: 100%;
        display: block;
        @include fontset(16,1.5,1.6,400);
        font-family: $font_content;
        position: relative;
        font-size: 1.25rem;
        
        &::after{
            position: absolute;
            content: '';
            bottom: -38px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $hover;
            transition: .4s ease;
            opacity: 0;
        }
        @media (min-width:768px) {
            &:hover{
                color: $hover;
                &::after{
                    opacity: 1;
                }
            }
        }
       
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 31px;
$bar-height: 2px;
$bar-spacing: 7px;
$bar-color:$hover;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 23px;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: rgba(255,255,255,255);
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@media (max-width:767px){
    $header_heright: 70px;

    .logo{
        width: 192px;
        height: 34px;
    }
    .header_show{
    }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 23px;
    }
    .nav_box nav{
        width: 100%;
        margin: auto;
        position: relative;
        top: 44%;
        transform: translateY(-50%);
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  $header_heright;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: #fff;
        ul {
            display: block;
            li {
                width: 100%;
                a {
                    padding: 40px 15px;
                    text-align: center;
                    text-decoration: none;
                    &::before,&::after{
                        display: none;
                    }
                    span{
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: -9px;
                            width: 100%;
                            height: 1px;
                            background-color: $hover;
                            opacity: 0;
                        }
                    }
                }
            }
            li.active{
                a{
                    color: $hover;
                    span:before{
                        opacity: 1;
                    }
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}

@media (max-width:767px) and (max-height:650px){
    .nav_box ul li a{
        padding: 22px 15px;
    }
}