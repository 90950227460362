.keywordPage{
    .item1{
        .container{
            margin: 0 auto;
            @media (min-width:1200px) {
                max-width: 1056px;
            }
        }
        .it1-bx{
            @media (max-width:767px) {
                padding-top: 15px;
            }
        }
        .sh-tibx{
            margin-bottom: 80px;
            @media (max-width:767px) {
                margin-bottom: 43px;
            }
        }
        .flex-bx{
            display: flex;
            justify-content: space-between;
            padding-bottom: 110px;
            @media (max-width:767px) {
                padding-bottom: 55px;
                display: block;
            }
            .t-bx{
                width: 29%;
                order: 2;
                margin-top: 42px;
                @media (max-width:767px) {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                .sear-btn{
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background-color: #f08300;
                    border: 1px solid $hover;
                    transition: .4s ease;
                    display: block;
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                    @media (max-width:767px) {
                        width: 100%;
                        border-radius: 5px;
                        margin-bottom: 20px;
                        height: 51px;
                    }
                    span{
                        position: relative;
                        @include fontset(16,3.8,1,400,#ffffff);
                        padding-left: 32px;
                        transition: .4s ease;
                        @media (max-width:767px) {
                            line-height: 3.6;
                        }
                        img{
                            position: absolute;
                            top: 42%;
                            transform: translateY(-50%);
                            left: 0;
                            transition: .4s ease;
                            width: 17px;
                        }
                        .unhove{
                            opacity: 1;
                        }
                        .hove{
                            opacity: 0;
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{
                            background-color: #fff;
                            span{
                                color: $hover;
                            }
                            .unhove{
                                opacity: 0;
                            }
                            .hove{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .b-bx{
                width: 65%;
                order: 1;
                @media (max-width:767px) {
                    width: 100%;
                }
                .sh-areabx:not(:last-child){
                    @media (max-width:767px) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}
.aboutpg{
    footer{
        z-index: 24;
    }
}
.aboutPage{
    .item1{
        padding: 0 60px;
        background: none;
        padding-bottom: 140px;
        @media (max-width:767px) {
            padding: 0 25px;
            padding-bottom: 110px;
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1056px;
            }
            @media (max-width:767px) {
                padding: 0 15px;
            }
        }
        .it1-bx{
            position: relative;
            .bg1{
                position: absolute;
                top: -2.5%;
                right: 0;
                @media (max-width:991px) {
                    width: 33%;
                    top: -0.5%;
                }
                @media (max-width:767px) {
                    width: 50%;
                    top: -1%;
                    z-index: 0;
                }
            }
            .bg2{

            }
            .sh-tibx{
                position: relative;
                z-index: 10;
            }
        }
        .flex-bx{
            margin-top: 80px;
            padding-bottom: 80px;
            display: flex;
            align-items: center;
            @media (max-width:991px) {
                display: block;
            }
            @media (max-width:767px) {
                margin-top: 45px;
                padding-bottom: 40px;
            }
            .l{
                width: 400px;
                height: 380px;
                position: relative;
                @media (max-width:991px) {
                    margin: 0 auto;
                    margin-bottom: 40px;
                }
                @media (max-width:767px) {
                    width: 100%;
                    max-width: 300px;
                    height: 290px;
                }
                @media (max-width:350px) {
                    height: 235px;
                }
                .cirlce{
                    width: calc(50% + 19px);
                    height: calc(50% + 34px);
                    position: absolute;
                    @media (max-width:767px) {
                        width: calc(50% + 15px);
                        height: calc(50% + 23px);
                    }
                    @media (max-width:350px) {
                        width: calc(50% + 12px);
                    }
                    .des{
                        @include fontset(22,1,2.2,400,$hover);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        position: absolute;
                        opacity: 0;
                        animation: lani .5s .7s ease-in-out forwards;
                        @media (max-width:767px) {
                            font-size: pxToEm(15);
                        }
                        @media (max-width:350px) {
                            font-size: pxToEm(12);
                        }
                    }
                }
                .cirl1{
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                   
                }
                .cirl2{
                    bottom: 0;
                    left: 0;
                    svg{
                        transform: rotate(-120deg);
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 0;
                        background: url(../images/about/leaf-01.png) no-repeat center center / contain;
                        width: 127px;
                        height: 62px;
                        z-index: 1;
                        opacity: 0;
                        animation: lani .5s .3s ease-in forwards;
                        @media (max-width:767px) {
                            width: 56%;
                            height: 44px;
                        }
                        @media (max-width:350px) {
                            width: 46%;
                            height: 46px;
                            right: 10px;
                            top: -5px;
                        }
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        top: 50%;
                        background: url(../images/about/leaf-01.png) no-repeat center center / contain;
                        z-index: 1;
                        transform: translateY(-50%) rotate(-120deg);
                        right: -45px;
                        height: 60px;
                        width: 128px;
                        opacity: 0;
                        animation: lani .5s .3s ease-in forwards;
                        @media (max-width:767px) {
                            width: 56%;
                            height: 44px;
                            right: -30px;
                        }
                        @media (max-width:350px) {
                            width: 45%;
                            height: 41px;
                            right: -17px;
                        }
                    }
                }
                .cirl3{
                    bottom: 0;
                    right: 0;
                    svg{
                        transform: rotate(120deg);
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 0;
                        background: url(../images/about/leaf-01.png) no-repeat center center / contain;
                        width: 127px;
                        height: 62px;
                        z-index: 1;
                        transform: rotate(-60deg);
                        opacity: 0;
                        animation: lani .5s .3s ease-in forwards;
                        @media (max-width:767px) {
                            width: 56%;
                            height: 44px;
                        }
                        @media (max-width:350px) {
                            width: 46%;
                            height: 46px;
                            left: 10px;
                            top: -4px;
                        }
                    }
                }
                @keyframes lani {
                    0%{
                        opacity: 0;
                    }
                    100%{
                        opacity: 1;
                    }
                }
                .st0{
                    fill:none;
                    stroke:#F08300;
                    stroke-width:59.8741;
                    stroke-miterlimit:10;
                }
                .osWLjzkr_0{
                    stroke-dasharray:861 863;
                    stroke-dashoffset:862;
                    animation:osWLjzkr_draw 1000ms ease-in-out 0ms forwards;
                }
                @keyframes osWLjzkr_draw{
                    100%{stroke-dashoffset:0;}
                }
                @keyframes osWLjzkr_fade{
                    0%{stroke-opacity:1;
                    }
                    94.44444444444444%{
                        stroke-opacity:1;
                    }
                    100%{
                        stroke-opacity:0;
                    }
                }
            }
            .r{
                margin-left: 8.5vw;
                position: relative;
                overflow: hidden;
                @media (max-width:991px) {
                    width: 100%;
                }
                @media (max-width:767px) {
                    margin-left: 0;
                }
                .ti{
                    @include fontset(36,1.2,1.8,700,$hover);
                    margin-bottom: 25px;
                    left: -100%;
                    position: relative;
                    animation: desani 1s 1s cubic-bezier(.86,0,.07,1) forwards;
                    @media (max-width:767px) {
                        font-size: pxToEm(26);
                    }
                }
                .mb.des{
                    margin-bottom: 18px;
                    animation: desani 1s 1.2s cubic-bezier(.86,0,.07,1) forwards;
                }
                .des{
                    @include fontset(30,1,3,400,#ffffff);
                    background-color: #000000;
                    padding: 18px 10px 9px 20px;
                    display: inline-block;
                    left: -100%;
                    position: relative;
                    animation: desani 1s 1.8s cubic-bezier(.86,0,.07,1) forwards;
                    @media (max-width:767px) {
                        font-size: pxToEm(21);
                        padding: 14px 4px 6px 15px;
                    }
                }
                @keyframes desani {
                    0%{
                        left: -100%;
                    }
                    100%{
                        left: 0;
                    }
                }
            }
        }
        .pro-list{
            display: flex;
            .l{
                width: 10.5%;
                position: relative;
                @media (max-width:767px) {
                    display: none;
                }
                .img-bx{
                    position: absolute;
                    top: -10px;
                    transition:opacity .1s ease-in-out;
                }
                .fix.img-bx{
                    position: fixed;
                    top: 400px;
                }
                .fix.img-bx.change{
                    position: absolute;
                    top: auto;
                    bottom: 42.5%;
                }
            }
            .r{
                width: 89.5%;
                @media (max-width:767px) {
                    width: 100%;
                }
                .pro-ti{
                    @include fontset(32,1,3.2,400,$hover);
                    @media (max-width:767px) {
                        font-size: pxToEm(26);
                        padding-left: 34px;
                        position: relative;
                    }
                    span{
                        @include fontset(16,1.2,1,400);
                        @media (max-width:767px) {
                            font-size: pxToEm(14);
                        }
                    }
                    .huimg{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 25px;
                    }
                }
                .pro-des{
                    @include fontset(16,1.8,1.6,400);
                    font-family: $font_content;
                    @media (max-width:767px) {
                        font-size: pxToEm(14);
                        line-height: 1.6;
                    }
                }
                .in-bx{
                    margin-bottom: 80px;
                    @media (max-width:767px) {
                        margin-bottom: 40px;
                    }
                    .img-bx{
                        width: 72px;
                        margin-top: 30px;
                        margin-bottom: 25px;
                        @media (max-width:767px) {
                            width: 60px;
                            margin-top: 15px;
                            margin-bottom: 21px;
                        }
                    }
                    .des-ti{
                        @include fontset(20,1.5,2,700);
                        font-family: $font_content;
                        @media (max-width:767px) {
                            font-size: pxToEm(16);
                            margin-bottom: 10px;
                        }
                    }
                }
                .rel-bx{
                    margin-bottom: 80px;
                    @media (max-width:767px) {
                        margin-bottom: 45px;
                    }
                    .img-bx{
                        width: 75px;
                        margin-top: 20px;
                        margin-bottom: 30px;
                        @media (max-width:767px) {
                            width: 71px;
                        }
                    }
                    .proul{
                        li{
                            display: flex;
                            align-items: center;
                            &:not(:last-child){
                                margin-bottom: 30px;
                                @media (max-width:767px) {
                                    margin-bottom: 15px;
                                }
                            }
                            .circle{
                                min-width: 63px;
                                width: 63px;
                                height: 63px;
                                background-color: #e58012;
                                @include fontset(20,3.5,1,700,#fff);
                                border-radius: 50%;
                                margin-right: 14px;
                                text-align: center;
                                @media (max-width:767px) {
                                    min-width: 49px;
                                    width: 49px;
                                    height: 49px;
                                    font-size: pxToEm(16);
                                }
                            }
                            .prd-des{
                                width: calc(100% - 77px);
                                @media (max-width:767px) {
                                    width: calc(100% - 63px);
                                }
                            }
                        }
                    }
                }
                .fa-bx{
                    padding-bottom: 90px;
                    @media (max-width:767px) {
                        padding-bottom: 30px;
                    }
                    .img-bx{
                        width: 53px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        @media (max-width:767px) {
                            margin-top: 25px;
                            margin-bottom: 25px;
                        }
                    }
                    .proul{
                        li{
                            display: flex;
                            align-items: center;
                            &:not(:last-child){
                                margin-bottom: 30px;
                                @media (max-width:767px) {
                                    margin-bottom: 15px;
                                }
                            }
                            .circle{
                                min-width: 63px;
                                width: 63px;
                                height: 63px;
                                background-color: #e58012;
                                @include fontset(20,3.5,1,700,#fff);
                                border-radius: 50%;
                                margin-right: 14px;
                                text-align: center;
                                @media (max-width:767px) {
                                    min-width: 49px;
                                    width: 49px;
                                    height: 49px;
                                    font-size: pxToEm(16);
                                }
                            }
                            .d-bx{
                                width: calc(100% - 77px);
                                @media (max-width:767px) {
                                    width: calc(100% - 63px);
                                }
                                .d-ti{
                                    @include fontset(16,1.8,1.6,700);
                                    position: relative;
                                    padding-right: 10px;
                                    display: inline-block;
                                    &::before{
                                        position: absolute;
                                        content: '';
                                        right: 0;
                                        top: 4px;
                                        width: 1px;
                                        height: 16px;
                                        background: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}