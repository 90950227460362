/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 25px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1232px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 30px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 30px;
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            border-bottom: 1px solid #dbdbdb;
            font-size: pxToEm(14);
            color: #000000;
            @media (max-width:767px) {
                padding:7px 0 3px;
                margin:0 20px 0 0; 
            }
            @media (min-width:768px) {
                margin:0 10px; 
                padding:7px 0;
            }
          
        }
        li.active a{
            border-color:black;
            color:black;
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: -18px;
    }

    a.flex-prev{
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left:  -18px;
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}

 ////   popup

//  .pop-act{
//     background: rgba(0, 0, 0, 0.8);
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 999;
//     display: none;
//     .vo_signbg{
//         background: url(../images/qr.png) no-repeat;
//         position: relative;
//         width: 300px;
//         height: 300px;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         @media (max-width:992px) {
//             background-size:450px;
//             background-position: center center;
//             width: 450px; 
//         }
//         @media (max-width:576px) {
//             width: 320px;
//             background-size:320px;
//         }
//     }
//     .clos-btn{
//         width: 60px;
//         position: absolute;
//         top: -30px;
//         right: -90px;
//         border-radius: 99px;
//         display: block;
//         height: 20px;
//         cursor: pointer;
//         @media (max-width:576px) {
//             // top: 0px;
//             right: -20px;
//             width: 40px;
//             padding-bottom: 40px;
//         }
//         &:before{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//         &:after{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(-45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//     }
// }
// .show-pop{
//     display: block !important;
//     }

.rfcon-bx{
    position: fixed;
    right: -70px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    z-index: 34;
    cursor: context-menu;
    transition: .4s ease;
   
    @media (min-width:768px) {
        &:hover{
            right: 0;
            .l .ti:before{
                border-right: none;
                border-left: 13px solid $hover;
                border-top: 9px dashed transparent;
                border-bottom: 9px dashed transparent;
            }
        }
    }
    .l{
        width: 50px;
        height: 201px;
        background-color: #fff;
        border-radius: 10px 0 0 10px;
        padding-top: 17px;
        box-shadow: 0 0 23.8px 1.3px rgba(0, 0, 0, 0.1);
        z-index: 5;
        .img-bx{
            text-align: center;
            margin-bottom: 10px;
        }
        .ti{
            @include fontset(20,1.2,2,400);
            width: 20px;
            margin: 0 auto;
            padding-bottom: 30px;
            position: relative;
            &::before{
                position: absolute;
                content: '';
                bottom: 0;
                left: 4px;
                border-right: 13px solid #000;
                border-top: 9px dashed transparent;
                border-bottom: 9px dashed transparent;
            }
        }
    }
    .r{
        height: 201px;
        width: 70px;
        background-color: #deaf6c;
        box-shadow: 0 0 23.8px 1.3px rgba(0, 0, 0, 0.1);
        position: relative;
        .link-bx{
            // margin-top: 35px;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%,-50%);
            a{
                display: block;
                text-align: center;
                opacity: .6;
                transition: .4s ease;
                &:nth-child(1){
                    // margin-bottom: 25px;
                }
                @media (min-width:768px) {
                    &:hover{
                        opacity: 1;
                    }
                }
                img{
                    margin: 0 auto;
                }
                
            }
        }
    }
    @media (max-width:767px) {
        bottom: 0;
        top: auto;
        right: auto;
        left: 0;
        transform: none;
        width: 100%;
        .l{
            width: auto;
            height: 50px;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            align-items: center;
            width: 40%;
            padding-left: 25px;
            padding-top: 0;
            .ti{
                width: auto;
                font-size: pxToEm(16);
                padding-bottom: 0;
                margin: 0;
                margin-left: 10px;
                margin-top: 4px;
                &::before{
                    display: none;
                }
            }
            .img-bx{
                width: 18px;
                margin-bottom: 0;
            }
        }
        .r{
            width: auto;
            height: 50px;
            background-color: #fff;
            box-shadow: none;
            width: 60%;
            padding-right: 25px;
            .link-bx{
                margin-top: 0;
                display: flex;
                align-items: center;
                height: 100%;
                a{
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    img{
                        width: 30px;
                      
                    }
                    &:first-child{
                        margin-bottom: 0;
                        margin-right: 9%;
                    }
                    span{
                        @include fontset(16,1,1,400);
                        font-family: $font_content;
                        margin-left: 5px;
                        
                    }
                }
            }
        }
    }

    @media (max-width:340px) {
        .r{
            padding-right: 10px;
            .link-bx a img{
                width: 19px;
            }
        }
        .l{
            padding-left: 10px;
        }
    }
}

.sh-tibx{
    text-align: center;
    .en-ti{
        @include fontset(32,1,3.2,400,$hover);
        margin-bottom: 12px;
        @media (max-width:767px) {
            font-size: pxToEm(26);
        }
    }
    .ti{
        @include fontset(30,1,3,400,);
        font-family: $font_content;
        @media (max-width:767px) {
            font-size: pxToEm(21);
        }
    }
}
.sh-tagbx{
    display: flex;
    flex-wrap: wrap;
    a:not(:last-child){
        margin-right: 15px;
    }
}
.active.sh-tag{
    background-color: $hover;
    color: #fff;
}
.sh-tag{
    @include fontset(16,1.2,.8,400,#000);
    padding: 12px 17px;
    border: 1px solid $hover;
    border-radius: 10px;
    background-color: #ffffff;
    display: block;
    font-family: $font_content;
    transition: .4s ease;
    margin-bottom: 14px;
    @media (min-width:768px) {
        &:hover{
            background-color: $hover;
            color: #fff;
        }
    }
    @media (max-width:767px) {
        font-size: pxToEm(14);
        padding: 10px 13px;
        border-radius: 5px;
    }
}

.sh-indban{
    position: fixed;
    top: 100px;
    width: 100%;
    @media (max-width:767px) {
        top: 70px;
    }
    h1{
        display: none;
    }
    .bgcover{
        background-size: cover !important;
        padding-bottom: 340px;
        @media (max-width:767px) {
            padding-bottom: 52%;
        }
    }
}
.sh-indban2{
    position: fixed;
    top: 100px;
    width: 100%;
    @media (max-width:767px) {
        top: 70px;
    }
    h1{
        display: none;
    }
    .bgcover{
        background-size: cover !important;
        padding-bottom: 1080px;
        @media (max-width:767px) {
            padding-bottom: 178%;
        }
    }
}

.sh-triobx{
    margin-top: 36vh;
    background: #fff;
    @media (max-width:767px) {
        margin-top: 51%;
    }
}

.sh-tribx{
    background:#fff;
    position: relative;
    &::before{
        position: absolute;
        content: '';
        top: -94px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 95px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: #fff;
        @media (max-width:767px) {
            height: 43px;
            top: -42px;
        }
    }
}

.sh-areabx,.sh-area3bx{
    .ti-bx{
        display: flex;
        align-items: baseline;
        padding-bottom: 10px;
        border-bottom: 1px solid $hover;
        margin-bottom: 30px;
        @media (max-width:767px) {
            margin-bottom: 20px;
        }
        .e{
            @include fontset(32,1,3.2,400,$hover);
            margin-right: 15px;
            @media (max-width:767px) {
                font-size: pxToEm(25);
                margin-right: 10px;               
            }
        }
        .t{
            @include fontset(18,1,1,400);
            @media (max-width:767px) {
                font-size: pxToEm(14);               
            }
        }
    }
    ul{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
            width: 48.5%;
            margin-bottom: 33px;
            border-radius: 10px;
            box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
            @media (max-width:767px) {
                border-radius: 5px;
                margin-bottom: 16px;
                width: 100%;
                box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
            }
            a{
                display: block;
                position: relative;
                border-radius: 10px;
                overflow: hidden;
                @media (max-width:767px) {
                    border-radius: 5px;
                }
                .img-bx{
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(#000,.7);
                        transition: .4s ease;
                        opacity: 0;
                    }
                    img{
                        width: 100%;
                    }
                }
                .des-bx{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 80%;
                    transform: translate(-50%,-50%);
                    text-align: center;
                    transition: .4s ease;
                    opacity: 0;
                    .ti{
                        @include fontset(21,1.1,2,700,#ffffff);
                        padding-bottom: 12px;
                        margin-bottom: 12px;
                        border-bottom: 1px solid #fff;
                        font-family: $font_content;
                        @media (max-width:767px) {

                        }
                    }
                    .des{
                        @include fontset(15,1.8,1.5,400,#fff);
                        font-family: $font_content;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        .img-bx::before{
                            opacity: 1;
                        }
                        .des-bx{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.sh-area3bx{
    ul{
        @media (min-width:992px) {
            justify-content: start;
        }
        li{
            width: 31%;
            @media (min-width:992px) {
                &:not(:nth-child(3n)){
                    margin-right: 3.5%;
                }
            }
            @media (max-width:991px) {
                width: 100%;
            }
        }
    }
}


.hiddendiv{
    display: none;
}


.overbg{
    // display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .5s linear;
    z-index: 1012;
    background-color: rgba(0,0,0,.8);
    pointer-events: none;
}
.modal-content{
    max-width: 767px;
    max-height: 600px;
    width:90%;
    height: 95%;
    padding:40px;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    margin-left: -7px;    
    z-index: 999;
    display: none;
    @media (max-width:640px){
        padding: 50px 2.5%;
        margin-left: 0px; 
    }
    .modalBox{
        width: 100%;
        height:100%;
        text-align:left;
        -webkit-overflow-scrolling:touch;
        background-color: #fff;        
        padding: 25px 20px;
        @include border(10px);
        font-size: pxToEm(12);
        color: #666;
        @media (max-width:640px){
            padding:20px 10px 20px 15px;
        }
    }
    #telbox{
        text-align: center;
        display: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        color:black;
        a[href^="tel:"]{
            max-width:450px;
            width: 80%;            
            color:#000;
            background-color: none;
            display: block;
            margin:35px auto 0;
            padding: 14px 0;
            border: 1px solid $hover;
            padding-top: 18px;
            border-radius: 10px;
            @media (max-width:767px) {
                margin-top: 20px;
                border-radius: 5px;
            }
            @media (min-width:768px) {
                &:hover{
                    background-color: $hover;
                    span{
                        color: #fff;
                    }
                }
            }
            span{
                position: relative;
            }
        }
        img{
            width: 28px;
        }
        > div{
            flex:0 0 100%;
        }
        .txt{
            font-size: pxToEm(20);
            margin:20px 0 0;
            font-family: $font_content;
            @media (max-width:767px) {
                margin:15px 0 0;
            }
        }
        .titx{
            font-size: pxToEm(22);
            @media (max-width:767px) {
                font-size: pxToEm(18);
            }
        }
    }
    
    &.telbox{
        height: 400px;
        #telbox{
            display: flex;
        }
    }
    &.fbbox{
        height: 400px;
        #fbbox{
            display: flex;
        }
    }
    &.agreebox .text-justify{
        display: block;
    }
    .text-justify{
        display: none;
        overflow-y:auto; 
        width: 100%;
        height:100%;
        padding-right: 10px;
        line-height: 1.5;
        text-align: initial;
        @media (max-width:640px){
            padding-right: 5px;
        }
    }
    .close{
        cursor: pointer;
        // border:2px solid #909399;
        @include border();
        z-index: 99993;
        position: absolute;
        display: block;
        width:40px;
        height: 40px;
        top:0px;
        right:0px;
        &:before, &:after {
            position: absolute;
            left: 18px;
            top:4px;
            content: ' ';
            height: 30px;
            width: 2px;
            background-color: #909399;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        @media(max-width: 640px){
            right:2.5%;
        }
    }
    //內容樣式
    h4{
        text-align: center;
        font-size: pxToEm(32);
        color:#303133;
        margin-bottom: 36px;
    }
    h6{
        font-size: pxToEm(24);
        color: #000000;
        font-weight: 700;
        margin-bottom: 12px;
    }
}