footer{
    z-index : 28;
    position: relative;
    padding: 36px 210px 30px 210px;
    background-color: #f0f0f0;
    // background: url(../images/pc_footer.jpg) no-repeat center center / cover;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background: url(../images/pc_footer_L.jpg) no-repeat center center / contain;
        width: 195px;
        height: 100%; 
        z-index: -1;   
        @media (max-width:767px){
            display: none;
        }
    }
    &::after{
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 177px;
        height: 100%;
        z-index: -1; 
        background: url(../images/pc_footer_R.jpg) no-repeat center center / contain;
        @media (max-width:767px){
            display: none;
        }
    }
    @media (max-width:1366px) {
        padding: 36px 100px 30px 100px;
    }
    @media (max-width:991px) {
        padding: 36px 20px 30px 20px;
    }
    @media (max-width:767px){
        padding: 33px 20px 30px 20px;
        background: url(../images/mb_footer.jpg) no-repeat center center / cover;
        margin-bottom: 45px;
    }
    .f-obx{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width:767px){
            display: block;
            text-align: center;
        }
        .l{
            .f-logo{
                width: 147px;
                height: 20px;
                background: url(../images/f-logo.png) no-repeat center center / cover;
                @media (max-width:991px){
                    width: 119px;
                    height: 15px;
                }
                @media (max-width:767px){
                    margin: 0 auto;
                    margin-bottom: 22px;
                }
            }
        }
        .r{
            ul{
                display: flex;
                align-items: center;
                @media (max-width:767px){
                    display: block;
                }
                li{
                    div{
                        @include fontset(16,1,.8,400);
                        font-family: $font_content;
                        span{
                            position: relative;
                          
                            img{
                                position: absolute;
                                left: 0;
                            }
                        }
                    }
                    &:not(:last-child){
                        margin-right: 50px;
                        @media (max-width:1366px) {
                            margin-right: 25px;
                        }
                        @media (max-width:991px){
                            margin-right: 10px;
                        }
                        @media (max-width:767px){
                            margin-right: 0;
                            margin-bottom: 13px;
                        }
                    }
                    &:nth-child(1){
                        div span{
                            padding-left: 33px;
                        }
                        div span img{
                            width: 18px;
                        }
                    }
                    &:nth-child(2){
                        div span{
                            padding-left: 38px;
                        }
                        div span img{
                            width: 27px;
                        }
                    }
                    &:nth-child(3){
                        div span{
                            padding-left: 27px;
                        }
                        div span img{
                            width: 13px;
                        }
                    }
                }
            }
            .copyright{
                @include fontset(14,1.2,.7,400,#959595);
                font-family: $font_content;
                text-align: right;
                margin-top: 20px;
                @media (max-width:767px){
                    text-align: center;
                }
                a{
                    @include fontset(14,1.2,.7,400,#959595);
                    font-family: $font_content;
                }
            }
        }
    }
}





