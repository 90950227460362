.tfmb{
    footer{
        z-index: 8;
        @media (max-width: 767px){
            margin-bottom: 100px;
        }
    }
}
.fix.rf-arbx{
    @media (min-width:768px) {
        position: fixed;
        top: 100px;
    }
    right: 15.2%;
    @media (max-width:1499px) {
        right: 3.6%;
    }
    @media (max-width:1099px) {
        right: 5%;
    }
    @media (max-width:991px) {
        right: 5.4%;
    }
    @media (max-width:767px) {
        right: auto;
    }
}
.rf-arbx{
    position: absolute;
    top: 160px;
    right: 15.2%;
    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    overflow: hidden;
    z-index: 50;
    @media (max-width:1499px) {
        right: 3.6%;
    }
    @media (max-width:1099px) {
        right: 5%;
    }
    @media (max-width:991px) {
        right: 5.4%;
    }
    @media (max-width:767px) {
        position: fixed;
        bottom: 50px;
        top: auto;
        right: auto;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 0px;
    }
    a{
        width: 47px;
        height: 80px;
        display: block;
        background: #fff;
        @include fontset(20,2,2,400,#000);
        transition: .4s ease;
        writing-mode: vertical-lr;
        text-align: center;
        @media (min-width:768px) {
            &:hover{
                color: #fff;
                background-color: $hover;
            }
        }
        @media (max-width:767px) {
            width: 33.3333%;
            height: 50px;
            color: #fff;
            background-color: #deaf6c;
            line-height: 3.5;
            writing-mode: inherit;
            font-size: pxToEm(16);
            &:not(:last-child){
                border-right: 1px solid #fff;
               
            }
        }
    }
}
.casesPage{
    .top_btn{
        @media (max-width:767px) {
            bottom: 110px;
        }
    }
    .top_btn.fix{
        @media (max-width:767px) {
            bottom: 30px;
        }
    }
    .container{
        margin: 0 auto;
        position: relative;
        z-index: 5;
        @media (min-width:1200px) {
            max-width: 1056px;
        }
    }
    .sh-tibx{
        margin-bottom: 80px;
        @media (max-width:767px) {
            margin-bottom: 40px;
        }
    }
    .it1-bx{
        margin-bottom: 110px;
        position: relative;
        .bg1{
            position: absolute;
            right: 0;
            bottom: 0;
            background: url(../images/cases/bg-1.png) no-repeat center center / contain;
            width: 875px;
            height: 365px;
            pointer-events: none;
            z-index: 0;
            @media (max-width:767px) {
                bottom: 13%;
                width: 487px;
                height: 215px;
                right: 2px;
            }
        }
        @media (max-width:767px) {
            margin-bottom: 90px;
        }
        .ar-obx{
            position: relative;
            .sh-area3bx:not(:last-child){
                margin-bottom: 20px;
                @media (max-width:767px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.classicPage{
    .sh-area3bx .ti-bx{
        // border-bottom: 1px solid #c99e38;
    }
    .sh-tibx .en-ti{
        // color: #c99e38;
    }
    .sh-area3bx .ti-bx .e{
        // color: #c99e38;
    }
    .sh-area3bx ul li a .des-bx{
        .thx{
            @include fontset(16,1.4,1,700,#c99e38);
            display: flex;
            align-items: center;
            justify-content: center;
            left: -18px;
            position: relative;
        }
        span{
            display: block;
            margin-top: 6px;
        }
        img{
            display: block;
        }
    }
    .item1 .it1-bx .ar-obx{
        .bg1{
            position: absolute;
            top: 100px;
            left: -438px;
            background: none;
            @media (max-width:767px) {
                left: -37px;
                width: 19%;
                top: -90px;
            }
        }
        .bg2{
            position: absolute;
            top: 265px;
            right: -435px;
            @media (max-width:767px) {
                right: -31px;
                width: 30%;
                top: 245px;
                z-index: -1;
            }
        }
        .bg3{
            position: absolute;
            bottom: 42%;
            right: -435px;
            @media (max-width:767px) {
                right: -27px;
                width: 16%;
                bottom: 40%;
            }
        }
        .bg4{
            position: absolute;
            bottom: 0;
            right: -435px;
            @media (max-width:767px) {
                right: -26px;
                width: 152%;
                z-index: -1;
                bottom: 14%;
            }
        }
        .bg5{
            position: absolute;
            bottom: 0;
            left: -435px;
        }
    }
    @media (min-width:768px) {
        .rf-arbx a:hover {
            // color: #fff;
            // background-color: #c99e38;
        }
    }
}

.casesdetailPage{
    .fix.rf-arbx.change{
        @media (min-width:768px){
            position: absolute;
            bottom: 115px;
            top: auto;
        }
    }
    .fix.rf-arbx{
        @media (min-width:768px){
            top: 100px;
        }
    }
    .rf-arbx {
        @media (min-width:768px){
            top: 0px;
        }
        a{
            @media (min-width:768px){
                width: 70px;
                height: 170px;
                line-height: 3;
                transition: .4s ease;
                &:hover{
                    background: #fff;
                    opacity: .6;
                   
                    color: #deaf6c;
                }
            }
            img{
                @media (min-width:768px){
                    position: relative;
                    left: 1px;
                    transition: .4s ease;
                    display: inline-block;
                    margin-bottom: 6px;
                }
            }
            &:last-child{
                @media (min-width:768px){
                    background: $hover;
                    color: #fff;
                    &:hover{
                        opacity: 1;
                        background: #deaf6c;
                    }
                }
            }
        }
    }
    .container{
        @media (min-width:1200px) {
            max-width: 1056px;
        }
    }
    .sh-indban .bgcover{
        padding-bottom: 24%;
        @media (max-width:767px){
            padding-bottom: 65.1%;
        }
    }
    .sh-triobx{
        margin-top: 24%;
        @media (max-width:767px){
            margin-top: 35vh;
        }
    }
    #it1{
        position: relative;
    }
    .it1-bx{
        @media (max-width:767px){
            padding-top: 20px;
        }
        .d-banner{
            width: 100%;
            margin-top: 115px;
            @media (max-width:767px){
                margin-top: 85px;
            }
            .bgcover{
                padding-bottom: 480px;
                background-size: cover !important;
                @media (max-width:767px){
                    padding-bottom: 46.8%;
                }
            }
            .slick-dots{
                width: 190px;
                height: 50px;
                position: absolute;
                margin: 0;
                right: -10px;
                top: -55px;
                // border: 1px solid #586c6b;
                pointer-events: none;
                z-index: 10;
                li{
                    display: flex;
                    width: 100%;
                }
                @media (max-width:767px) {
                    // width: 60px;
                    // height: 60px;
                    // position: absolute;
                    // top: -100px;
                    // left: auto;
                    // right: 10px;
                    // transform: none;
                    width: 120px;
                }
                li.slick-active{
                    opacity: 1;
                    i{
                        &::after{
                            animation: dotani 5.3s linear forwards;
                            @media (max-width:767px) {
                                animation: dotanimo 5.3s linear forwards;
                            }
                        }
                    }
                }
                @keyframes dotani {
                    0%{
                        width: 0;
                    }
                    100%{
                        width: 100px;
                    }
                }
                @keyframes dotanimo {
                    0%{
                        width: 0;
                    }
                    100%{
                        width: 50px;
                    }
                }
                li{
                    @include fontset(16,1,.6,700,#787878);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0 ;
                    height: auto !important;
                    opacity: 0;
                    transition: .4s ease;
                    font-family: $font_content;
                    i{
                        color: $hover;
                        padding-right: 120px;
                        margin-right: 20px;
                        position: relative;
                        @media (max-width:767px) {
                            padding-right: 50px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            left: 36px;
                            top: 60%;
                            transform: translateY(-50%);
                            width: 100px;
                            height: 2px;
                            background-color: #dcdddd;
                            @media (max-width:767px) {
                                left: 18px;
                                width: 50px;
                            }
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            left: 36px;
                            top: 60%;
                            transform: translateY(-50%);
                            height: 2px;
                            background-color: $hover;
                            @media (max-width:767px) {
                                left: 18px;
    
                            }
                        }
                    }
                    @media (max-width:767px) {
                        font-size: pxToEm(13);
                    }
                }
            }
        }
        .caflex-bx{
            .r .ti.ti-bx{
                position: relative;
                margin-bottom: 50px;
                @media (max-width:991px) {
                    display: block;
                    margin-bottom: 25px;
                }
                .ti-t{
                    @include fontset(32,1,3.2,400,$hover);
                    @media (max-width:991px){
                        font-size: pxToEm(26);
                    }
                }
                .ti-b{
                    position: absolute;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    @media (max-width:991px) {
                        position: relative;
                        right: auto;
                        top: auto;
                        transform: none;
                    }
                    .con-bx{
                        max-width: 460px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-left: auto;
                        @media (max-width:1199px) {
                            max-width: 400px;
                        }
                        @media (max-width:991px) {
                            max-width: 100%;
                            display: block;
                            margin-top: 23px;
                        }
                        a{
                            display: block;
                            width: 32%;
                            box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
                            height: 50px;
                            text-align: center;
                            border-radius: 10px;
                            transition: .4s ease;
                            @media (max-width:991px) {
                                width: 100%;
                                height: 48px;
                                box-shadow: 0 0 7.3px 0.25px rgba(0, 0, 0, 0.18);
                                &:not(:last-child){
                                    margin-bottom: 20px;
                                }
                            }
                            @media (min-width:768px) {
                                &:hover{
                                    opacity: .5;
                                }
                            }
                            span{
                                padding-left: 45px;
                                position: relative;
                                line-height: 3.5;
                                margin-left: 0;
                                font-size: pxToEm(16);
                                @media (max-width:991px) {
                                    font-size: pxToEm(14);
                                    line-height: 3.8;
                                    padding-left: 38px;
                                }
                                img{
                                    position: absolute;
                                    left: 0;
                                    top: 38%;
                                    transform: translateY(-50%);
                                    width: 36px;
                                    @media (max-width:991px) {
                                        width: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .inf-bx{
            margin-top: 55px;
            display: flex;
            align-items: center;
            border: 1px solid $hover;
            border-radius: 10px;
            padding: 40px 32px;
            position: relative;
            overflow: hidden;
            @media (max-width:991px){
                display: block;
                border-radius: 5px;
                padding: 20px 16px;
            }
            &::before{
                position: absolute;
                content: '';
                left: 0;
                width: calc(18.5% + 20px); 
                height: 100%;
                top: 0;
                background-color: #fef1ea;
                z-index: 0;
                @media (max-width:991px){
                    width: 100%;
                    height: 150px;
                }
            }
            .l{
                position: relative;
                z-index: 5;
                width: 18.5%;
                @media (max-width:991px){
                    width: 100%;
                    padding-bottom: 5%;
                }
                .imgti{
                    @include fontset(22,1,2.2,700);
                    margin-top: 15px;
                    text-align: center;
                    @media (max-width:991px){
                        font-size: pxToEm(16);
                    }
                }
                .img-bx{
                    max-width: 145px;
                    width: 100%;
                    @media (max-width:991px){
                        max-width: 400px;
                        margin: 0 auto;
                        text-align: center;
                    }
                    img{
                        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
                        @media (max-width:991px){
                            box-shadow: 0 0 7.3px 0.5px rgba(0, 0, 0, 0.18);
                            width: 80px;
                            margin: 0 auto;
                        } 
                    }
                }
            }
            .r{
                padding-left: 4%;
                width: 81.5%;
                @media (max-width:991px){
                    padding-left: 0;
                    margin-top: 6%;
                    width: 100%;
                }
                .r-bx{
                    display: flex;
                    @media (max-width:991px){
                        display: block;
                    }
                }
                .flist{
                    width: 48%;
                    @media (max-width:991px){
                        width: 100%;
                    }
                }
                .tlist{
                    width: 44%;
                    margin-left: 4%;
                    padding-left: 4%;
                    border-left: 1px solid $hover;
                    position: relative;
                    padding-bottom: 93px;
                    @media (max-width:991px){
                        width: 100%;
                        margin-left: 0;
                        padding-left: 0;
                        width: 100%;
                        border: none;
                        padding-bottom: 0;
                    }
                    .des-ti{
                        margin-bottom: 15px;
                    }
                    .b{
                        width: 100%;
                    }
                    .t{
                        @media (max-width:991px){
                            margin-top: 17px;
                        }
                    }
                }
                .ti{
                    @include fontset(32,1,3.2,400,$hover);
                    margin-bottom: 25px;
                    @media (max-width:991px){
                        font-size: pxToEm(26);
                        margin-bottom: 15px;
                    }
                    span{
                        @include fontset(18,1,1,400);
                        margin-left: 15px;
                        @media (max-width:991px){
                            font-size: pxToEm(14);
                            margin-left: 10px;
                        }
                    }
                }
                li{
                    padding-bottom: 10px;
                    border-bottom: 2px solid #dcdddd;
                    display: flex;
                    align-items: center;
                    @media (max-width:991px){
                        display: block;
                        border-bottom: 1px solid #dcdddd;
                    }
                    &:not(:first-child){
                        margin-top: 25px;
                        @media (max-width:991px){
                            margin-top: 17px;
                        }
                    }
                    .des-ti{
                        margin-right: 23px;
                    }
                }
            }
            .r{
                // width: 37.6%;
                // padding-left: 2.5%;
                // margin-top: 57px;
                position: relative;
                .b{
                    position: absolute;
                    bottom: 0;
                    @media (max-width:991px){
                        position: relative;
                        bottom: auto;
                        margin-top: 17px;
                    }
                    .des{
                        padding-bottom: 14px;
                        border-bottom: 2px solid #dcdddd;
                        @media (max-width:991px){
                            border-bottom: 1px solid #dcdddd;
                        }
                    }
                }
                .des-ti{
                    @media (min-width:992px){
                        min-width: 99px;
                    }
                }
                .adr{
                    display: block;
                    @include fontset(16,1.2,1,400);
                    position: relative;
                    padding-left: 26px;
                    font-family: $font_content;
                    padding-bottom: 14px;
                    border-bottom: 2px solid #dcdddd;
                    @media (max-width:991px){
                        border-bottom: 1px solid #dcdddd;
                    }
                    img{
                        width: 15px;
                        position: absolute;
                        left: 0;
                    }
                }
                .tel{
                    display: block;
                    @include fontset(16,1.2,1,400);
                    position: relative;
                    padding-left: 26px;
                    font-family: $font_content;
                    padding-bottom: 14px;
                    border-bottom: 2px solid #dcdddd;
                    margin-top: 15px;
                    @media (max-width:991px){
                        border-bottom: 1px solid #dcdddd;
                    }
                    img{
                        width: 20px;
                        position: absolute;
                        left: 0;
                    }

                }
            }
            .des-ti{
                @include fontset(20,1.2,1,400);
                padding-left: 15px;
                position: relative;
                font-family: $font_content;
                @media (max-width:991px){
                    font-size: pxToEm(16);
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 3px;
                    width: 5px;
                    height: 80%;
                    background-color: #deaf6c;
                }
            }
            .des{
                @include fontset(16,1.2,1,400);
                font-family: $font_content;
                @media (max-width:991px){
                    font-size: pxToEm(14);
                    margin-top: 14px;
                }
            }
        }
    }
    .full-bg{
        width: 100%;
        margin-top: 80px;
        @media (max-width:991px){
            margin-top: 40px;
        }
        .bgcover{
            padding-bottom: 35.4%;
            background-size: cover !important;
            @media (max-width:991px){
                padding-bottom: 35.4%;
            }
        }
    }
    .ti-bx{
        display: flex;
        align-items: flex-end;
        .en{
            @include fontset(32,.8,3.2,400,$hover);
            margin-right: 15px;
            @media (max-width:767px){
                font-size: pxToEm(26);
            }
        }
        .t{
            @include fontset(18,1,1,400);
            @media (max-width:767px){
                font-size: pxToEm(14);
            }
        }
    }
    .map-bx{
        margin-top: 85px;
        @media (max-width:991px){
            margin-top: 45px;
        }
        iframe{
            margin-top: 30px;
            margin-bottom: 85px;
            width: 100%;
            height: 480px;
            @media (max-width:767px){
                margin-top: 15px;
                margin-bottom: 45px;
                height: 240px;
                height: 150px;
            }
        }
    }
    .morelist{
        margin-top: 30px;
        padding-bottom: 55px;
        cursor: url("../images/cased/mouse.png"),auto;
        @media (max-width:767px){
            padding-bottom: 25px;
        }
        .slick-list{
            padding-left: 440px ;
            // margin-left: -150px;
            // width: 100%;
        }
        li{
            width: 460px;
            margin: 0 50px;
            overflow: hidden;
            @media (max-width:767px){
                width: 80vw;
                margin: 0 8px;
            }
            a{
                display: block;
                @media (min-width:768px){
                    &:hover{
                        .img-bx::before{
                            opacity: 1;
                        }
                        .img-bx .hov-more{
                            opacity: 1;
                        }
                        .des::before{
                            width: 100%;
                        }
                    }
                }
                .img-bx{
                    box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;
                    @media (max-width:767px){
                        border-radius: 5px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(#000,.7);
                        transition: .4s ease;
                        opacity: 0;
                    }
                    .hov-more{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        @include fontset(16,1.5,1,400,#fff);
                        transition: .4s ease;
                        opacity: 0;
                        display: flex;
                        align-items: center;
                        font-family: $font_content;
                        img{
                            margin-right: 10px;
                        }
                    }
                }
                .da{
                    @include fontset(20,1.2,1,400,$hover);
                    margin-bottom: 10px;
                    margin-top: 20px;
                    @media (max-width:767px){
                        font-size: pxToEm(16);
                        margin-top: 16px;
                        margin-bottom: 5px;
                    }
                }
                .des{
                    @include fontset(22,1.64,2.2,700);
                    position: relative;
                    @include clamp(1.64,2);
                    height: auto;
                    max-height: 3.28em;
                    font-family: $font_content;
                    @media (max-width:767px){
                        font-size: pxToEm(16);
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 3px;
                        background: $hover;
                        transition: .6s ease;
                    }
                }
            }
        }
    }
    .morebor{
        margin-bottom: 40px;
        width: 100%;
        height: 2px;
        background-color: #dcdddd;
        @media (max-width:767px){
            height: 1px;
        }
    }
    .back-btn{
        @include fontset(16,1.2,.8,400,#000);
        padding: 12px 17px;
        border: 1px solid $hover;
        border-radius: 10px;
        background-color: #ffffff;
        font-family: $font_content;
        transition: .4s ease;
        display: block;
        width: 125px;
        margin: 0 auto;
        margin-bottom: 140px;
        text-align: center;
        @media (min-width:768px) {
            &:hover{
                background-color: $hover;
                color: #fff;
            }
        }
        @media (max-width:767px) {
            font-size: pxToEm(14);
            padding: 10px 13px;
            border-radius: 5px;
            margin-bottom: 100px;
            width: 102px;
        }
        // width: 90px;
        // margin: 0 auto;
        // display: block;
        // padding-left: 45px;
        // position: relative;
        // margin-bottom: 140px;
        // @media (max-width:767px){
        //     margin-bottom: 100px;
        // }
        // .hov{
        //     opacity: 1;
        // }
        // .unhov{
        //     opacity: 0;
        // }
        // img{
        //     transition: .4s ease;
        //     position: absolute;
        //     left: 0;
        //     width: 35px;
        //     top: 43%;
        //     transform: translateY(-50%);
        // }
        // span{
        //     @include fontset(16,1.5,.8,400);
        //     transition: .4s ease;
        //     font-family: $font_content;
        //     @media (max-width:767px){
        //         font-size: pxToEm(14);
        //     }
        // }
        // @media (min-width:768px){
        //     &:hover{
        //         .hov{
        //             opacity: 0;
        //         }
        //         .unhov{
        //             opacity: 1;
        //         }
        //         span{
        //             color: $hover;
        //         }
        //     }
        // }
    }
}


.classicdetailPage{
    .sh-indban{
        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            background-color: #10183f;
        }
        &::after{
            position: absolute;
            content: '';
            top: calc(50% - 15px);
            left: 50%;
            transform: translate(-50%,-50%);
            background: url(../images/classicd/mb_text.png) no-repeat  center / contain;
            width: 88vw;
            height: 120px;
            @media (min-width:768px){
                width: 30.2vw;
                top: calc(50% - 45px);
            }
            @media (max-width:767px) and (min-width:450px){
                top: calc(30% - 15px);
            }
        }
    }
    // .sh-tibx .en-ti{
    //     color: #c99e38;
    // }
    // .it1-bx .d-banner .slick-dots li i{
    //     color: #c99e38;
    // }
    // .it1-bx .d-banner .slick-dots li i:after{
    //     background: #cc9e38;
    // }
    // .it1-bx .inf-bx{
    //     border: 1px solid #cc9e38;
    // }
    // .it1-bx .inf-bx:before{
    //     background-color: #efefef;
    // }
    // .it1-bx .inf-bx .r .ti{
    //     color: #c99e38;
    // }
    // .ti-bx .en{
    //     color: #c99e38;
    // }
    // .morelist li a .da{
    //     color: #c99e38;
    // }
    // .morelist li a .des:before{
    //     background: #c99e38;
    // }
    // .morelist{
    //     cursor: url("../images/classicd/mouse.png"),auto;
    // }
    // .back-btn{
    //     @media (min-width:768px){
    //         &:hover{
    //             span{
    //                 color: #c99e38;
    //             }
    //         }
    //     }
    // }
}