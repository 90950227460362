.contactPage{
    .container{
        @media (min-width:1200px) {
            max-width: 1056px;
        }
    }
    .overbg{
        // display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .5s linear;
        z-index: 1012;
        background-color: rgba(0,0,0,.8);
        pointer-events: none;
    }
    .modal-content{
        max-width: 767px;
        max-height: 600px;
        width:90%;
        height: 71vh;
        padding:40px;
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        margin-left: -7px;    
        z-index: 999;
        display: none;
        @media (max-width:640px){
            padding: 50px 2.5%;
            margin-left: 0px; 
        }
        .modalBox{
            width: 100%;
            height:100%;
            text-align:left;
            -webkit-overflow-scrolling:touch;
            background-color: #fff;        
            padding: 25px 20px;
            @include border(10px);
            font-size: pxToEm(12);
            color: #666;
            @media (max-width:640px){
                padding:20px 10px 20px 15px;
            }
        }
        #telbox{
            text-align: center;
            display: none;
            align-items: center;
            justify-content: center;
            height: 100%;
            color:black;
            a[href^="tel:"]{
                max-width:450px;
                width: 80%;            
                color:white;
                background-color: #1e4c5e;
                display: block;
                margin:35px auto 0;
                padding: 15px 0;
            }
            > div{
                flex:0 0 100%;
            }
            .txt{
                font-size: pxToEm(20);
                margin:10px 0 0;
            }
        }
        
        &.telbox{
            height: 400px;
            #telbox{
                display: flex;
            }
        }
        &.agreebox .text-justify{
            display: block;
        }
        .text-justify{
            display: none;
            overflow-y:auto; 
            width: 100%;
            height:100%;
            padding-right: 10px;
            line-height: 1.5;
            text-align: initial;
            @media (max-width:640px){
                padding-right: 5px;
            }
        }
        .close{
            cursor: pointer;
            // border:2px solid #909399;
            @include border();
            z-index: 99993;
            position: absolute;
            display: block;
            width:40px;
            height: 40px;
            top:0px;
            right:0px;
            &:before, &:after {
                position: absolute;
                left: 18px;
                top:4px;
                content: ' ';
                height: 30px;
                width: 2px;
                background-color: #909399;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
            @media(max-width: 640px){
                right:2.5%;
            }
        }
        //內容樣式
        h4{
            text-align: center;
            font-size: pxToEm(32);
            color:#303133;
            margin-bottom: 36px;
        }
        h6{
            font-size: pxToEm(24);
            color: #000000;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
    .item1{
        .it1-bx{
            @media (max-width:767px){
                padding-top: 0px;
            }
        }
        .form-bx{
            margin-top: 80px;
            @media (max-width:767px){
                margin-top: 40px;
            }
            .flex-bx{
                display: flex;
                justify-content: space-between;
                @media (max-width:767px){
                    display: block;
                }
                .form-group{
                    width: 48%;
                    margin-bottom: 24px;
                    @media (max-width:767px){
                        width: 100%;
                        margin-bottom: 8px;
                    }
                }
                .form-radiobx{
                    width: 48%;
                    @media (max-width:767px){
                        width: 100%;
                        margin-top: 30px;
                    }
                }
            }
            input{
                background-color: none;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $hover;
                height: 34px;
            }
            label,.ti{
                @include fontset(20,1.75,.8,400);
                position: relative;
                font-family: $font_content;
                @media (max-width:767px){
                    font-size: pxToEm(16);
                }
                span{
                    color: $hover;
                    font-family: $font_content;
                }
            }
            .ti{
               
            }
            .help-block{
                @include fontset(16,1.6,.7,500,$hover);
                height: 15px;
                margin-top: 8px;
                @media (max-width:767px){
                    font-size: pxToEm(14);
                }
            }
            .form-radiobx{
                // display: flex;
                margin-top: 5px;
                @media (max-width:991px) {
                    flex-wrap: wrap;
                    margin-top: 0px;
                }
                .radio-bx{
                    display: flex;
                    flex-wrap: wrap;
                    @media (max-width:767px){
                        margin-top: 5px;
                    }
                }
                .form-radio{
                    display: flex;
                    flex-wrap: wrap;
                    @media (max-width:991px) {
                        margin-bottom: 0px;
                    }
                    input{
                        display: none;
                    }
                    input[type=text]{
                        display: block;
                        width: 167px;
                        margin-left: -10px;
                        position: relative;
                        top: -3px;
                        @include fontset(16,1.75,.8,500);
                        @media (max-width:767px) {
                            top: 0;
                            height: 28px;
                            width: 110px;
                        }
                    }
                    label{
                        padding-left: 27px;
                        margin-right: 23px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        @media (max-width:768px) {
                            padding-left: 22px;
                            margin-bottom: 3px;
                        }
                    }
                    input[type=radio]+label:before{
                        width: 18px;
                        height: 18px;
                        background-color: #fff;
                        border: 1px solid #f58905;
                        border-radius: 100px;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        content: "";
                        @media (max-width:768px) {
                            top: 7px;
                            width: 15px;
                            height: 15px;

                        }
                    }
                    input[type=radio]:checked+label:after{
                        width: 8px;
                        height: 8px;
                        background-color: $hover;
                        border-radius: 100px;
                        position: absolute;
                        content: "";
                        top: 15px;
                        left: 5px;
                        @media (max-width:768px) {
                            top: 11px;
                            width: 7px;
                            height: 7px;
                            left: 4px;
                        }
                    }
                    .form-radio{
                        position: relative;
                        margin-right: 25px;
                        @media (max-width:992px) {
                            width: 50%;
                            margin-right: 0px;
                        }
                        
                        &:first-child{
                            margin-right: 30px;
                            @media (max-width:992px) {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
            .sel-ti{
                @include fontset(20,1.75,.8,400);
                position: relative;
                font-family: $font_content;
                margin-bottom: 30px;
                @media (max-width:768px) {
                    font-size: pxToEm(16);
                    margin-bottom: 12px;
                    margin-top: 25px;
                }
            }
            .arbx{
                &:not(:last-child){
                    margin-bottom: 50px;
                    @media (max-width:768px) {
                        margin-bottom: 25px;
                    }
                }
                .active.t-ti{
                    border-bottom: 1px solid $hover;
                    &::before{
                        transform: none;
                    }
                }
                .t-ti{
                    @include fontset(32,1,3.2,400,$hover);
                    font-family: $bil;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #dcdddd;
                    cursor: pointer;
                    position: relative;
                    transition: .4s ease;
                    @media (max-width:768px) {
                        font-size: pxToEm(26);
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: 11px;
                        right: 0;
                        width: 22px;
                        height: 11px;
                        background: url(../images/contact/sel.png) no-repeat center center / contain;
                        transform: rotate(180deg);
                        transition: .6s ease;
                        @media (max-width:768px) {
                            width: 19px;
                            height: 8px;
                        }
                    }
                    span{
                        @include fontset(18,1,1,400,#000);
                        @media (max-width:768px) {
                            font-size: pxToEm(14);
                        }
                    }
                }
                .b-bx{
                    padding: 40px 46px;
                    border-bottom:1px solid $hover;
                    padding-bottom: 0;
                    background-color: #fff9f5;
                    display: none;
                    @media (max-width:768px) {
                        padding: 20px 23px;
                    }
                }
            }
            .form-check-box{
                display: flex;
                flex-wrap: wrap;
                @media (max-width:768px) {
                    justify-content: space-between;
                }
                .form-chitem{
                    position: relative;
                    margin-bottom: 40px;
                    width: 21%;
                    @media (max-width:768px) {
                        margin-top:5px;
                        width: 47.5%;
                        margin-bottom: 30px;
                    }
                    .img-bx{
                        max-width: 195px;
                        width: 100%;
                        box-shadow: 0 0 14.6px 0.5px rgba(0, 0, 0, 0.18);
                        margin-bottom: 5px;
                    }
                    label{
                        display: block;
                        position: static;
                    }
                    &:not(:nth-child(4n)){
                        margin-right: 4%;
                        @media (max-width:768px) {
                            margin-right: 0;
                        }
                    }
                }
                input{
                    display: none;
                }
                label{
                    cursor: pointer;
                    color: #000000;
                    margin-right: 0px;
                    p{
                        color: #000000;
                        font-size: pxToEm(20);
                        letter-spacing: 2.7px;
                        margin-left: 30px;
                        position: relative;
                        @media (max-width:767px){
                            font-size: pxToEm(16);
                        }
                    }
                }
                .form-chitem input[type=checkbox]+label p:before{
                     width: 20px;
                     height: 20px;
                     border: 1px solid $hover;
                     position: absolute;
                     content: "";
                     top: 8px;
                     left: -26px;
                     @media (max-width:767px){
                         width: 18px;
                         height: 18px;
                         top: 6px;

                     }
                }
                .form-chitem input[type=checkbox]:checked+label p:after{
                 content: "";
                 position: absolute;
                 width: 10px;
                 height: 12px;
                 background: url(../images/contact/checkbox.png) no-repeat;
                 top: 11px;
                left: -20px;
                 @media (max-width:767px){
                     width: 9px;
                     height: 12px;
                     top: 8px;
                 }
                }
            } 

            .form-textbx{
                @media (max-width:767px) {
                    margin-top: 5px;
                }
                .textarea-wrapper{
                    height: 276px;
                    overflow: hidden;
                    border:none;
                    padding:0px 0px 10px;
                    margin-top: 10px;
                    background-color: #eeeeee;
                    @media (max-width:767px) {
                        height: 140px;
                    }
                    textarea{
                        min-height: 262px;
                        height: 0;
                        overflow: hidden;
                        border-bottom:none;
                        padding-top:14px;
                        padding-left: 11px;
                        text-rendering: auto;
                        -webkit-writing-mode: horizontal-tb !important;
                        background-color: #eeeeee;
                        resize: none;
                        border: none;
                        font-family: $font_content;
                        cursor:text;
                        @media (max-width:767px) {
                            min-height: 126px;
                        }
                        &:focus{
                                    border: none ;
                                }
                    }
                }
                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                    background-color: $hover;
                    width: 2px;
                }
                .mCSB_scrollTools .mCSB_draggerRail{
                    background-color: #dcdddd;
                    width: 2px;
                }
                .mCSB_scrollTools{
                    right: -5px;
                }
                .mCSB_inside>.mCSB_container{
                    margin-right: 13px;
                }
                .mCSB_scrollTools .mCSB_draggerContainer{
                    top: 15px;
                    left: -12px;
                }
            }

            .agree{
                text-align: left;
                margin-top: 20px;
                margin-bottom: 55px;
                @media (max-width:767px) {
                    margin-top: 15px;
                    margin-bottom: 27px;
                }
            }
            .agr-des{
                text-align: center;
                @include fontset(16,1.63,0,400,#00B1FF);
                margin-top: 10px;
                margin-bottom: 30px;
                @media (max-width:767px) {
                    font-size: pxToEm(13);
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            .check_radi {
                padding-left: 30px;
                height: 32px;
                text-align: left;
                width: auto;
                position: relative;
                cursor: pointer;
                user-select: none;
                display: inline-block;
                @media (max-width:767px) {
                    padding-left: 30px;
                }
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    left: 0;
                    top:0;
                    z-index: 5;
                    width:24px;
                    height: 24px;
                }
                .checkmark {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    height: 18px;
                    width: 18px;
                    border-radius: 0;
                    background-color: #ffffff;
                    border: solid 1px #f08300;
                    @media (max-width:767px) {
                        top: 4px;
                    }
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 5px;
                    display: none;
                    width: 10px;
                    height: 13px;
                    background: url('../images/contact/checkbox.png') no-repeat center / contain ;
                }
                input:checked~.checkmark:after {
                    display: block;
                }
                a[data-popup]{
                    color:#000000;
                    position: relative;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }

            

            .send-btn{
                width: 240px;
                height: 57px;
                border-radius: 10px;
                border: solid 1px #f08300;
                transition: .4s ease;
                display: block;
                margin: 0 auto;
                text-align: center;
                @include fontset(16,4,.8,400);
                margin-bottom: 140px;
                @media (max-width:767px) {
                    margin-bottom: 110px;
                    width: 210px;
                    height: 48px;
                    border-radius: 5px;
                    font-size: pxToEm(14);
                    line-height: 3.8;
                }
                @media (min-width:768px) {
                    &:hover{
                        background-color: $hover;
                        color: #fff;
                        span:before{
                            border-left: 9px solid #fff;
                        }
                    }
                }
                span{
                    padding-right: 27px;
                    position: relative;
                    @media (max-width:767px) {
                        padding-right: 20px;
                    }
                    &::before{
                        position: absolute;
                        right: 0;
                        top: 2px;
                        content: '';
                        border: 6px dashed transparent;
                        border-right: none;
                        border-left: 9px solid #f08300;
                        transition: .4s ease;
                        @media (max-width:767px) {
                            top: 1px;
                        }
                    }
                }
            }
        }
    }
}